import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@material-ui/core";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { CLAUSE_DATA, getPara } from "./utils/ClauseTypeUtils";
import React, { useEffect, useMemo, useState } from "react";

import { AmountEdit } from "./AmountEdit";
import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { LinkPhraseRequest } from "../../State/documentState";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import { truncateString } from "../../../Utils/DataModifierUtil/dataModUtil";

export default function IndemnityClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    currencyList,
    sentenceData,
    hasData,
  } = props;

  const [counter, setCounter] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const [showAddCap, setShowAddCap] = useState(false);
  const [editCap, setEditCap] = useState(false);
  const [capId, setCapId] = useState(-1);
  const [showAddExtentCost, setShowAddExtentCost] = useState(false);
  const [showAddTriggeringEvents, setShowAddTriggeringEvents] = useState(false);
  const [showAddPayer, setShowAddPayer] = useState(false);
  const [showAddPayee, setShowAddPayee] = useState(false);

  useEffect(() => {
    hasData || getClauseDataByType(fileId, "indemnity");
  }, [fileId]);

  const data = React.useMemo(
    () => clauseData?.indemnity && clauseData.indemnity,
    [clauseData?.indemnity]
  );

  const updates = useMemo(
    () => updatedClauseData?.indemnity || {},
    [updatedClauseData]
  );

  const indemnity = useMemo(() => {
    if (updates?.indemnity) {
      let sortData = updates.indemnity.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const payer = useMemo(() => {
    if (updates?.transaction) {
      let payer = updates.transaction.filter(
        (data: any) => data.type === "payer"
      );
      let sortData = payer.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const payee = useMemo(() => {
    if (updates?.transaction) {
      let payee = updates.transaction.filter(
        (data: any) => data.type === "payee"
      );
      let sortData = payee.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const cap = useMemo(() => {
    if (updates?.amounts) {
      let sortData = updates.amounts.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      sortData.map((data: any) => {
        const index = currencyList.findIndex(
          (list) => list.currencyTypeId === data.currency_type_id
        );
        const typeIndex = currencyList.findIndex(
          (list) => list.currencyName === data.currency_type
        );
        if (index > -1) {
          const type = currencyList[index].currencyName;
          data.currency_type = type;
        }
        if (!data.currency_type_id && typeIndex > -1) {
          const id = currencyList[typeIndex].currencyTypeId;
          data.currency_type_id = id;
        }
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const extent_cost = useMemo(() => {
    if (updates?.bi_1) {
      let sortData = updates.bi_1.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const trigger_events = useMemo(() => {
    if (updates?.bi_2) {
      let sortData = updates.bi_2.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(indemnity, CLAUSE_DATA.indemnity_clause.heading);
    setCounter(0);
  };

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let indemnity_data = indemnity?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let amounts = cap?.filter((data: any) => data.para_id !== item.para_id);

      let transaction = updates.transaction?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let bi_1 = extent_cost.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let bi_2 = trigger_events.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let updatedData = {
        ...updates,
        indemnity: indemnity_data,
        amounts,
        transaction,
        bi_1,
        bi_2,
      };
      console.log(updatedData, "delete updated");

      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      console.log(diff, "diff");

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "indemnity", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, indemnity, data, fileId]
  );

  const deleteCap = React.useCallback(
    (item: any) => {
      let amounts = cap?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let updatedData = {
        ...updates,
        amounts,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "indemnity", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, cap, data, fileId]
  );

  const deleteTransaction = React.useCallback(
    (item: any, type: string) => {
      let transaction = updates?.transaction?.filter(
        (data: any) =>
          type !== data.type ||
          data.para_id !== item.para_id ||
          data.sentence_id !== item.sentence_id
      );
      let updatedData = {
        ...updates,
        transaction,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "indemnity", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, updates, data, fileId]
  );

  const deleteExtentCost = React.useCallback(
    (item: any) => {
      let bi_1 = extent_cost?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let updatedData = {
        ...updates,
        bi_1,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "indemnity", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, extent_cost, data, fileId]
  );

  const deleteTriggerEvents = React.useCallback(
    (item: any) => {
      let bi_2 = trigger_events?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let updatedData = {
        ...updates,
        bi_2,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "indemnity", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, trigger_events, data, fileId]
  );

  const editPhrase = React.useCallback(
    (data: any, index: number) => {
      let amounts = cap.slice();
      amounts[index] = data;
      let updatedData = {
        ...updates,
        amounts,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "indemnity", diff, updatedData);
      }
    },
    [cap]
  );

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">{CLAUSE_DATA.indemnity_clause.heading}</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", indemnity);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${indemnity?.length}`
                  : indemnity?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (indemnity?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", indemnity);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2 w-100 d-flex flex-column">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">Yes</p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        props.onEdit(
                          `${CLAUSE_DATA.indemnity_clause.heading}`,
                          0,
                          false,
                          [],
                          -1,
                          "",
                          indemnity
                        );
                        setShowAdd(true);
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited="indemnity"
                savedInsight={!isNullOrUndefined(indemnity) ? indemnity : []}
                savedParentClauseDataPoint={indemnity}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
                parentClauseType="indemnity"
              />
            )}
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {indemnity?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(index, item, "indemnity")
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.para
                        ? item.para
                        : getPara(
                            props?.sentenceData,
                            item.para_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-indemnity"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteClause(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
            <div className="d-flex flex-column mt-2">
              <div className="clause-sub-heading">
                <p className="m-0">Cap</p>
                {!showAddCap ? (
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      handleAddEdit(
                        props,
                        "Indemnity/Reimbursements/Costs Amount",
                        cap,
                        setShowAddCap
                      );
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                ) : (
                  <EditFeature
                    fileId={props.fileId}
                    toBeEdited="Indemnity/Reimbursements/Costs Amount"
                    savedInsight={!isNullOrUndefined(cap) ? cap : []}
                    savedParentClauseDataPoint={cap}
                    editOptionSelected={(selected: boolean) => {
                      props.editOptionSelected(selected);
                    }}
                    childInEditId={props.childInEditId}
                    onClose={() => {
                      setShowAddCap(false);
                    }}
                    clauseDataByType={data}
                    updatedClauseDataByType={updates}
                    parentClauseType="indemnity"
                  />
                )}
              </div>
              <div className="mt-2">
                <Scrollable maxHeight={200}>
                  {cap?.map((capItem: any, index: number) => (
                    <div key={index}>
                      <div>
                        {editCap && capId === index ? (
                          <AmountEdit
                            dataPointName="Limitation Of Liability Amount"
                            currencyList={currencyList}
                            savedInsight={
                              !isNullOrUndefined(capItem) ? capItem : []
                            }
                            editPhrasesRequest={(
                              newPhraseRequest: LinkPhraseRequest
                            ) => {
                              editPhrase(newPhraseRequest, index);
                            }}
                            clearNormalizedEdit={() => setEditCap(false)}
                            selectedAmount={capItem}
                          />
                        ) : (
                          <div
                            className={
                              editCap && capId === index
                                ? " mr-1 toc-content toc-content-active animate__animated animate__fadeInDown"
                                : " mr-1 toc-content toc-content-default animate__animated animate__fadeInDown"
                            }
                            style={{
                              border:
                                editCap && capId === index
                                  ? "2px solid #882f5e"
                                  : "",
                              marginBottom: "1px",
                            }}
                          >
                            <div
                              className="bi-clause-clickable"
                              onClick={() =>
                                props.onClickHighlight(
                                  index,
                                  capItem,
                                  "Limitation Of Liability Amount"
                                )
                              }
                            >
                              <div className="d-flex flex-row px-3">
                                <span style={{ color: "#4D4D4D" }}>
                                  {index + 1}. &nbsp;&nbsp;
                                </span>
                                <span>
                                  {capItem.total < 0 ? (
                                    <img
                                      src="/static_images/empty-dash-grey-icn.svg"
                                      alt="empty"
                                    />
                                  ) : capItem.total > -1 &&
                                    (capItem.total + " " + capItem.currency)
                                      .length > 30 ? (
                                    <DarkTooltip
                                      title={
                                        capItem.total + " " + capItem.currency
                                      }
                                      placement={"right-end"}
                                    >
                                      <>
                                        {truncateString(
                                          capItem.total +
                                            " " +
                                            capItem.currency,
                                          30
                                        )}
                                      </>
                                    </DarkTooltip>
                                  ) : (
                                    `${capItem.total || "_"}  ${
                                      capItem.currency || "_"
                                    }`
                                  )}
                                  <DarkTooltip
                                    title="Edit"
                                    placement="right-end"
                                  >
                                    <img
                                      src="/static_images/new-edit-icon.svg"
                                      alt="edit"
                                      onClick={() => {
                                        setEditCap(true);
                                        setCapId(index);
                                      }}
                                    />
                                  </DarkTooltip>
                                </span>
                                <img
                                  className="cursor-pointer ml-auto "
                                  style={{ zIndex: 2 }}
                                  src="/static_images/delete-insight-icn.svg"
                                  alt="delete-currency-insight"
                                  data-toggle="modal"
                                  data-target="#deleteInsightModal"
                                  onClick={() => deleteCap(capItem)}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div
                        className={
                          editCap && capId === index
                            ? "mr-1 toc-content toc-content-active animate__animated animate__fadeInDown p-2 mb-1"
                            : "mr-1 toc-content toc-content-default animate__animated animate__fadeInDown p-2 mb-1 cursor-pointer"
                        }
                        style={{
                          border:
                            editCap && capId === index
                              ? "2px solid #882f5e"
                              : "",
                        }}
                        key={index}
                        onClick={() =>
                          props.onClickHighlight(
                            index,
                            capItem,
                            "Limitation Of Liability Amount"
                          )
                        }
                      >
                        {capItem.amountString
                          ? capItem.amountString
                          : getPara(
                              props?.sentenceData,
                              capItem.para_id,
                              capItem.sentence_id,
                              capItem.table && capItem.table[0]?.row,
                              capItem.table && capItem.table[0]?.column
                            )}
                      </div>
                    </div>
                  ))}
                </Scrollable>
              </div>
            </div>
            <div className="clause-sub-heading mt-2">
              <p className="m-0">{CLAUSE_DATA.indemnity_clause.extent_cost}</p>
              {!showAddExtentCost ? (
                <DarkTooltip
                  title="Add"
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Indemnity/Reimbursements/Costs Extent of Cost",
                      extent_cost,
                      setShowAddExtentCost
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Indemnity/Reimbursements/Costs Extent of Cost"
                  savedInsight={
                    !isNullOrUndefined(extent_cost) ? extent_cost : []
                  }
                  savedParentClauseDataPoint={extent_cost}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddExtentCost(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="indemnity"
                />
              )}
            </div>
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {extent_cost?.map((item: any, index: number) => (
                  <div
                    className="clause-div"
                    onClick={() =>
                      props.onClickHighlight(
                        index,
                        item,
                        CLAUSE_DATA.indemnity_clause.extent_cost
                      )
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.string}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteExtentCost(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
          </div>

          <div className="d-flex flex-column mt-2">
            <div className="clause-sub-heading">
              <p className="m-0">
                {CLAUSE_DATA.indemnity_clause.triggering_events}
              </p>
              {!showAddTriggeringEvents ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Indemnity/Reimbursements/Costs Triggering Event",
                      trigger_events,
                      setShowAddTriggeringEvents
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Indemnity/Reimbursements/Costs Triggering Event"
                  savedInsight={
                    !isNullOrUndefined(trigger_events) ? trigger_events : []
                  }
                  savedParentClauseDataPoint={trigger_events}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddTriggeringEvents(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="indemnity"
                />
              )}
            </div>
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {trigger_events?.map((triggerItem: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(
                        index,
                        triggerItem,
                        CLAUSE_DATA.indemnity_clause.triggering_events
                      )
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {triggerItem.string}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteTriggerEvents(triggerItem)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
          </div>
          <div className="d-flex flex-column mt-2">
            <div className="clause-sub-heading">
              <p className="m-0">payer</p>
              {!showAddPayer ? (
                <DarkTooltip
                  title="Add"
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(props, "payer", payer, setShowAddPayer);
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Indemnity/Reimbursements/Costs Payer"
                  savedInsight={!isNullOrUndefined(payer) ? payer : []}
                  savedParentClauseDataPoint={payer}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddPayer(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="indemnity"
                />
              )}
            </div>
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {payer?.map((payerItem: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(index, payerItem, "Payer")
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {payerItem.string}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() =>
                        deleteTransaction(payerItem, payerItem.type)
                      }
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
          </div>
          <div className="d-flex flex-column mt-2">
            <div className="clause-sub-heading">
              <p className="m-0">payee</p>
              {!showAddPayee ? (
                <DarkTooltip
                  title="Add"
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(props, "payee", payee, setShowAddPayee);
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Indemnity/Reimbursements/Costs Payee"
                  savedInsight={!isNullOrUndefined(payee) ? payee : []}
                  savedParentClauseDataPoint={payee}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddPayee(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="indemnity"
                />
              )}
            </div>
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {payee?.map((payeeItem: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(index, payeeItem, "Payee")
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {payeeItem.string}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() =>
                        deleteTransaction(payeeItem, payeeItem.type)
                      }
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
