import * as changesets from "json-diff-ts";

import { ClauseType, ClauseTypeMap } from "../../../../Constants/ClauseType";
import {
  LinkParaRequest,
  LinkSentenceRequest,
  SentencesData,
  editedParas,
  editedSentences,
  paraInfo,
  sentenceInfo,
  tableInfo,
} from "../../../../State/documentState";
import React, { Component } from "react";
import {
  deleteDataFromObligation,
  getClauseDataFormat,
  getClauseObjects,
  getEditedPayload,
} from "../../../ClauseComponent/utils/ClauseTypeUtils";

import { AnyClass } from "is-what";
import { duration } from "@material-ui/core";
import { table } from "console";
import { getParasFromChild, getSentencesFromChild, getTableCellsFromChild, paraBiMap } from "../../../Utils/docUtils";
import SaveOrCancelSentence from "../saveOrCancelSentence";

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editPresentSentences: (presentValue: LinkSentenceRequest) => void;
  dataPointName: string;
  highlightedId: number[] | null;
  savedHighlightedSentences: sentenceInfo[] | null;
  saveHighlightedSentences: (
    savedHighlightedSentences: sentenceInfo[] | null
  ) => void;
  savedPresentData: any;
  saveHighlightedId: (highlightedId: number[] | null) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  otherClauses: (type: string) => void;
  fileId: string;
  clauseType: string;
  wholeData: any;
  postClauseDataByType: (
    fileId: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  clauseItem: any;
  onClose: any;
  clauseData: any;
}

interface State {
  highlight: boolean;
  requiredString: string;
  origData: any;
  present: string;
}

const assignmentInfoBiMap: any = {
  "Subletting/Assignment Notice Info": "Notice Required",
  "Subletting/Assignment Consent Info": "Consent Required",
  "Exception to Confidentiality": "",
};

export const assignmentInfoBiValue: any = {
  "Subletting/Assignment Notice Info": "notice",
  "Subletting/Assignment Consent Info": "consent",
  "Exception to Confidentiality": "c_except",
};

export default class AssignmentInfoAddEdit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      highlight: false,
      requiredString: "Yes",
      origData: props.clauseDataByType,
      present: "Yes",
    };
  }

  render() {
    let {
      editOptionSelected,
      dataPointName,
      highlightedId,
      saveHighlightedId,
      savedHighlightedTableCells,
      saveHighlightedTableCells,
      savedHighlightedSentences,
      savedPresentData,
      saveHighlightedSentences,
    } = this.props;

    let { requiredString, present } = this.state;
    return (
      <div className="row toc-content toc-content-default">
        <div className="col-md-12">
          <div className="row mt-1">
            <div
              className="col-md-12 m-0 bi-label-clickable"
              style={{ fontWeight: 600 }}
            >
              Add {dataPointName}
            </div>
            <div className="col-md-12 my-1 edit-title-header">
              Add/Edit the {dataPointName} clause of your contract here
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 my-2">
              {assignmentInfoBiMap[dataPointName]}
            </div>
            <div
              className="col-md-3 my-2 ml-0"
              style={{ margin: "auto" }}
              onClick={() => this.setState({ requiredString: "Yes" })}
            >
              <img
                className="cursor-pointer"
                src={
                  requiredString === "Yes"
                    ? "/static_images/radio-active.svg"
                    : "/static_images/radio-inactive.svg"
                }
                alt="radio-btn"
              />
              &nbsp;&nbsp;
              <span>Yes</span>
            </div>
            <div
              className="col-md-3 my-2 ml-0"
              style={{ margin: "auto" }}
              onClick={() => this.setState({ requiredString: "No" })}
            >
              <img
                className="cursor-pointer"
                src={
                  requiredString === "No"
                    ? "/static_images/radio-active.svg"
                    : "/static_images/radio-inactive.svg"
                }
                alt="radio-btn"
              />
              &nbsp;&nbsp;
              <span>No</span>
            </div>
          </div>
          <SaveOrCancelSentence
          enableHighlightOption={present === "Yes"}
          dataPointName={dataPointName}
          editOptionSelected={editOptionSelected}
          editDataPoint={() => {
            this.editPresent();
            this.props.saveHighlightedSentences(null);
            saveHighlightedTableCells(null);
            editOptionSelected(false);
            this.props.onClose();
          }}
          savedHighlightedSentences={savedHighlightedSentences}
          saveHighlightedDataPoint={this.saveHighlightedDataPointCallback}
          enableSaveBtn={true}
          saveHighlightedSentences={(
            savedHighlightedSentences: sentenceInfo[] | null
          ) => saveHighlightedSentences(savedHighlightedSentences)}
          savedHighlightedTableCells={savedHighlightedTableCells}
          saveHighlightedTableCells={(
            savedHighlightedTableCells: tableInfo[] | null
          ) => saveHighlightedTableCells(savedHighlightedTableCells)}
          previouslyLinkedSentences={
            getSentencesFromChild(savedPresentData).length > 0
              ? getSentencesFromChild(savedPresentData)
              : undefined
          }
          previouslyLinkedTableCells={
            getTableCellsFromChild(savedPresentData).length > 0
              ? getTableCellsFromChild(savedPresentData)
              : undefined
          }
          onClose={this.props.onClose}
          otherClauses={(type: string) => this.props.otherClauses(type)}
        />
        </div>
      </div>
    );
  }

  saveHighlightedDataPointCallback = () => {
    let { saveHighlightedDataPoint, dataPointName } = this.props;
    saveHighlightedDataPoint(dataPointName);
  }

  saveHighlightedDataPoint(highlight?: boolean) {
    let { saveHighlightedDataPoint, dataPointName } = this.props;
    saveHighlightedDataPoint(dataPointName);
  }

  getAddedAndDeletedSentences(
    changedLinkedSentences: sentenceInfo[] | null,
    previousLinkedSentences: sentenceInfo[]
  ) {
    let addedSentences: sentenceInfo[] = [];
    let deletedSentences: sentenceInfo[] = [];
    
    let { requiredString } = this.state;

    if (requiredString === "No") {
      changedLinkedSentences = [];
    }

    if (previousLinkedSentences.length !== 0) {
      if (changedLinkedSentences !== null) {
        if (changedLinkedSentences.length !== 0) {
          //get newly added elements
          for (let i = 0; i < changedLinkedSentences.length; i++) {
            let addedExists = false;
            for (let j = 0; j < previousLinkedSentences.length; j++) {
              if (
                changedLinkedSentences[i].paraId ===
                previousLinkedSentences[j].paraId
              ) {
                if (
                  changedLinkedSentences[i].sentenceId ===
                  previousLinkedSentences[j].sentenceId
                ) {
                  addedExists = true;
                  break;
                }
              }
            }
            if (addedExists === false) {
              addedSentences.push({
                paraId: changedLinkedSentences[i].paraId,
                sentenceId: changedLinkedSentences[i].sentenceId,
                rowId: -1,
                columnId: -1,
                requirement: requiredString === "Yes" ? true : false,
              });
            }
          }
  
          //get deleted elements
          for (let i = 0; i < previousLinkedSentences.length; i++) {
            let deletedExists = false;
            for (let j = 0; j < changedLinkedSentences.length; j++) {
              if (
                previousLinkedSentences[i].paraId ===
                changedLinkedSentences[j].paraId
              ) {
                if (
                  previousLinkedSentences[i].sentenceId ===
                  changedLinkedSentences[j].sentenceId
                ) {
                  deletedExists = true;
                  break;
                }
              }
            }
            if (deletedExists === false) {
              deletedSentences.push({
                paraId: previousLinkedSentences[i].paraId,
                sentenceId: previousLinkedSentences[i].sentenceId,
                rowId: previousLinkedSentences[i].rowId,
                columnId: previousLinkedSentences[i].columnId,
                requirement: requiredString === "Yes" ? true : false,
              });
            }
          }
        } else if (changedLinkedSentences.length === 0) {
          for (let i = 0; i < previousLinkedSentences.length; i++) {
            deletedSentences.push({
              paraId: previousLinkedSentences[i].paraId,
              sentenceId: previousLinkedSentences[i].sentenceId,
              rowId: previousLinkedSentences[i].rowId,
              columnId: previousLinkedSentences[i].columnId,
              requirement: requiredString === "Yes" ? true : false,
            });
          }
        }
      }
    } else {
      if (changedLinkedSentences !== null) {
        if (changedLinkedSentences.length !== 0) {
          //adding for first time, newly added elements
          for (let i = 0; i < changedLinkedSentences.length; i++) {
            addedSentences.push(changedLinkedSentences[i]);
            addedSentences[i].requirement = requiredString === "Yes" ? true : false;
          }
        }
      }
    }

  
    let addedDeletedSentences: editedSentences = {
      upsert: addedSentences,
      deleted: deletedSentences,
    };

  
    return addedDeletedSentences;
  }
  
  getAddedAndDeletedTableCells(
    changedLinkedTableCells: tableInfo[] | null,
    previousLinkedTableCells: tableInfo[]
  ) {
    let addedTableCells: tableInfo[] = [];
    let deletedTableCells: tableInfo[] = [];
    if (
      previousLinkedTableCells.length !== 0 &&
      previousLinkedTableCells !== null
    ) {
      if (
        changedLinkedTableCells !== null &&
        changedLinkedTableCells.length !== 0
      ) {
        //newly added
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          let addedCellExists = false;
          for (let j = 0; j < previousLinkedTableCells.length; j++) {
            if (
              changedLinkedTableCells[i].paraId ===
                previousLinkedTableCells[j].paraId &&
              changedLinkedTableCells[i].rowId ===
                previousLinkedTableCells[j].rowId &&
              changedLinkedTableCells[i].columnId ===
                previousLinkedTableCells[j].columnId
            ) {
              addedCellExists = true;
              break;
            }
          }
          if (addedCellExists === false) {
            addedTableCells.push({
              paraId: changedLinkedTableCells[i].paraId,
              rowId: changedLinkedTableCells[i].rowId,
              columnId: changedLinkedTableCells[i].columnId,
            });
          }
        }
  
        //deleted elements
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          let deletedCellExists = false;
          for (let j = 0; j < changedLinkedTableCells.length; j++) {
            if (
              previousLinkedTableCells[i].paraId ===
                changedLinkedTableCells[j].paraId &&
              previousLinkedTableCells[i].rowId ===
                changedLinkedTableCells[j].rowId &&
              previousLinkedTableCells[i].columnId ===
                changedLinkedTableCells[j].columnId
            ) {
              deletedCellExists = true;
              break;
            }
          }
          if (deletedCellExists === false) {
            deletedTableCells.push({
              paraId: previousLinkedTableCells[i].paraId,
              rowId: previousLinkedTableCells[i].rowId,
              columnId: previousLinkedTableCells[i].columnId,
            });
          }
        }
      } else {
        //all deleted
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          deletedTableCells.push({
            paraId: previousLinkedTableCells[i].paraId,
            rowId: previousLinkedTableCells[i].rowId,
            columnId: previousLinkedTableCells[i].columnId,
          });
        }
      }
    } else if (
      changedLinkedTableCells !== null &&
      changedLinkedTableCells.length !== 0
    ) {
      //newly added
      for (let i = 0; i < changedLinkedTableCells.length; i++) {
        addedTableCells.push(changedLinkedTableCells[i]);
      }
    }
  
    //Convert table cells json to sentence level json
    let upsertSentences: sentenceInfo[] = [];
    let deletedSentences: sentenceInfo[] = [];
  
    for (let i = 0; i < addedTableCells.length; i++) {
      upsertSentences.push({
        paraId: addedTableCells[i].paraId,
        sentenceId: -1,
        rowId: addedTableCells[i].rowId,
        columnId: addedTableCells[i].columnId,
      });
    }
  
    for (let i = 0; i < deletedTableCells.length; i++) {
      deletedSentences.push({
        paraId: deletedTableCells[i].paraId,
        sentenceId: -1,
        rowId: deletedTableCells[i].rowId,
        columnId: deletedTableCells[i].columnId,
      });
    }
  
    let editedTableCellsAsSentence: editedSentences = {
      upsert: upsertSentences,
      deleted: deletedSentences,
    };
    return editedTableCellsAsSentence;
  }
  
  showLinkClause() {
    let {
      editOptionSelected,
      dataPointName,
      saveHighlightedSentences,
      saveHighlightedDataPoint,
      highlightedId,
      saveHighlightedId,
      saveHighlightedTableCells
    } = this.props;
    let { highlight, present } = this.state;
    let { savedHighlightedTableCells, savedHighlightedSentences } = this.props;
    let sentenceLength: number =
      savedHighlightedSentences !== null && savedHighlightedSentences.length > 0
        ? savedHighlightedSentences.length
        : 0;
    let tableLength: number =
      savedHighlightedTableCells !== null &&
      savedHighlightedTableCells.length > 0
        ? savedHighlightedTableCells.length
        : 0;
    let sentencesLength = sentenceLength + tableLength;

    if (
      ((savedHighlightedSentences !== null &&
        savedHighlightedSentences !== undefined &&
        savedHighlightedSentences.length > 0) ||
        (savedHighlightedTableCells !== null &&
          savedHighlightedTableCells !== undefined &&
          savedHighlightedTableCells.length > 0)) &&
      highlight === true
    ) {
      return (
        <div className="row">
          <div className="col-md-4 my-2">Clause</div>
          <div className="col-md-6 my-2">
            <span className="link-to" style={{ color: "#C1C1C1" }}>
              Edit linked sentence(s)
            </span>
          </div>
          <div className="col-md-2 pr-0 display-flex">
            <span className="linked-para-count">{sentencesLength}</span>
          </div>
        </div>
      );
    } else {
      if (highlight === true) {
        return (
          <>
            <div className="row">
              <div className="col-md-4 my-2">Clause</div>
              <div className="col-md-6 my-2">
                <span
                  className="link-to cursor-pointer"
                  style={{ color: "#C1C1C1" }}
                >
                  Link to sentences(s)
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 link-to-info">
                Please highlight the sentences on the document, you wish to link
                to this data point and click on save.
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div className="row">
            <div className="col-md-4 my-2">Clause</div>
            <div className="col-md-6 my-2">
              <span
                className="link-to cursor-pointer"
                style={{ color: "#88305F" }}
                onClick={() => this.linkSingleSentence()}
              >
                Link to sentences(s)
              </span>
            </div>
          </div>
        );
      }
    }
  }

  saveOrCancelSingleSentence() {
    let { savedHighlightedSentences, savedHighlightedTableCells } = this.props;
    return (
      <div className="row my-2">
        <div className="col-md-5" />
        <div className="col-md-7">
          <span
            className="add-datapoint"
            style={{ textDecoration: "none" }}
            onClick={() => this.onCancel()}
          >
            Cancel
          </span>
          {(savedHighlightedSentences !== null &&
            savedHighlightedSentences.length > 0) ||
          (savedHighlightedTableCells !== null &&
            savedHighlightedTableCells.length > 0) ? (
            <span
              className="upload-yellow-btn ml-4"
              id="save-btn"
              onClick={() => this.onSave()}
            >
              Save
            </span>
          ) : (
            <span className="upload-disable-btn ml-4" id="save-btn">
              Save
            </span>
          )}
        </div>
      </div>
    );
  }

  linkSingleSentence() {
    let { saveHighlightedDataPoint, dataPointName } = this.props;
    this.setState({ highlight: true });

    this.props.saveHighlightedTableCells(null);
    this.props.saveHighlightedSentences(null);

    this.props.editOptionSelected(true);
    saveHighlightedDataPoint(dataPointName);
  }

  editPresent() {
    let {
      clauseType,
      sentenceData,
      clauseDataByType,
      fileId,
      updatedClauseDataByType,
      postClauseDataByType,
      parentClauseType,
      updatedClauseData,
      clauseData,
    } = this.props;

    let addSentenceRequest = this.addOrRemovePresent("add");

    let updatedData = updatedClauseDataByType;
    let newData = updatedData;
    let obligationData = updatedClauseData.obligationStatements;
    
    if (this.state.present === "Yes") {
      if (addSentenceRequest?.editedSentences?.upsert) {
        let addedData = addSentenceRequest.editedSentences.upsert;
        for (let i = 0; i < addedData.length; i++) {
          newData = getClauseDataFormat(
            "add",
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData
          );
          obligationData = deleteDataFromObligation(
            "sentence",
            addedData[i],
            obligationData
          );
        }
      }
      if (addSentenceRequest?.editedSentences?.deleted) {
        let deletedData = addSentenceRequest.editedSentences.deleted;
        for (let i = 0; i < deletedData.length; i++) {
          newData = getClauseDataFormat(
            "remove",
            clauseType as ClauseType,
            deletedData[i],
            newData,
            sentenceData
          );
        }
      }
    } else {
      newData = getClauseObjects(clauseType as ClauseType);
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });

    const obligationDiff = changesets.diff(
      clauseData?.obligationStatements?.raw_content,
      obligationData,
      { children: "$index" }
    );

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType || clauseType,
        diff,
        newData
      );
    }
    if (obligationDiff.length > 0) {
      postClauseDataByType(
        fileId,
        "obligation_statements",
        obligationDiff,
        obligationData
      );
    }
  }

  onSave() {
    let addSentenceRequest = this.addOrRemovePresent("add");
    this.props.editPresentSentences(addSentenceRequest);
    this.props.editOptionSelected(false);
    this.props.saveHighlightedTableCells(null);
    this.props.saveHighlightedSentences(null);
    this.setState({ highlight: false });
    //this.editPresent(addSentenceRequest);
    this.props.onClose();
  }

  onCancel() {
    this.props.editOptionSelected(false);
    this.props.saveHighlightedSentences(null);
    this.props.saveHighlightedTableCells(null);
    this.setState({ highlight: false });
    this.props.onClose();
  }

  addOrRemovePresent(action: string): LinkSentenceRequest {
    let { savedHighlightedSentences, savedHighlightedTableCells, savedPresentData } = this.props;
    let tempPresentList: LinkSentenceRequest = { data: "", mode: "" };


    //edited sentences
    //let previousLinkedSentences: sentenceInfo[] = [];
    let previousLinkedSentences = getSentencesFromChild(savedPresentData);
    let editedSentencesObject = this.getAddedAndDeletedSentences(
      savedHighlightedSentences,
      previousLinkedSentences
    );

    //edited table cells
    let previousLinkedTableCells: tableInfo[] = [];
    let editedTableCellsObject = this.getAddedAndDeletedTableCells(
      savedHighlightedTableCells,
      previousLinkedTableCells
    );

    //merge both
    let mergeEditedSentences = this.mergeEditedSentences(
      editedTableCellsObject,
      editedSentencesObject
    );

    if (action === "add") {
      if (
        savedHighlightedSentences !== null ||
        savedHighlightedTableCells !== null || this.state.requiredString === "No"
      ) {
        tempPresentList = {
          mode: "manual",
          editedSentences: mergeEditedSentences,
        };
      } else {
        tempPresentList = {
          mode: "retain",
        };
      }
    }
    return tempPresentList;
  }

  mergeEditedSentences(
    firstEditedSentences: editedSentences,
    secondEditedSentences: editedSentences
  ) {
    let { dataPointName } = this.props;
    let mergedAddedSentences: sentenceInfo[] =
      firstEditedSentences.upsert.concat(secondEditedSentences.upsert);
    let mergedDeletedSentences: sentenceInfo[] =
      firstEditedSentences.deleted.concat(secondEditedSentences.deleted);

    let mergedEditedSentences: editedSentences = {
      upsert: mergedAddedSentences,
      deleted: mergedDeletedSentences,
      bi: assignmentInfoBiValue[dataPointName],
    };
    return mergedEditedSentences;
  }
}
