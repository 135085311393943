import { CurrencyType, SentencesData } from "../../../State/documentState";

import { ClauseType } from "../../../Constants/ClauseType";

export const getPara = (
  sentenceData: SentencesData,
  paraID: number,
  sentenceId?: number,
  row: number = -1,
  col: number = -1
) => {
  if (col > -1 && row > -1) {
    let table = sentenceData?.hierarchy?.[paraID]?.table?.slice() || [];
    if (table && table.length > 0) {
      for (let i = 0; i < table.length; i++) {
        let rowData = table[i]?.Row?.slice() || [];
        if (rowData && rowData.length > 0) {
          for (let j = 0; j < rowData.length; j++) {
            if (rowData[j]?.Column === col && rowData[j]?.Row === row) {
              return rowData[j]?.Content;
            }
          }
        }
        return sentenceData?.hierarchy?.[paraID]?.para;
      }
    }
    return sentenceData?.hierarchy?.[paraID]?.para;
  }

  if (sentenceId && sentenceId > -1) {
    return sentenceData?.hierarchy?.[paraID]?.sentences?.[sentenceId]?.sentence;
  }
  return sentenceData?.hierarchy?.[paraID]?.para;
};

export const isParaExist = (obj: any, list: any) => {
  var x;
  for (x in list) {
    if (list[x].para_id === obj.paraId) {
      return true;
    }
  }
  return false;
};

export const isSentenceExist = (obj: any, list: any) => {
  var x;
  for (x in list) {
    if (
      list[x].para_id === obj.paraId &&
      list[x].sentence_id === obj.sentenceId
    ) {
      return true;
    }
  }
  return false;
};

function containsPresentObject(obj: any, list: any) {
  var x;
  for (x in list) {
    if (
      list[x].para_id === obj.paraId &&
      list[x].sentence_id === obj.startSentenceId
    ) {
      return true;
    }
  }
  return false;
}

export function containsPhrase(obj: any, list: any) {
  var x;
  for (x in list) {
    if (
      list[x].para_id === obj.paraId &&
      list[x].start_sentence_id === obj.startSentenceId
    ) {
      return true;
    }
  }
  return false;
}

export function containIndemnityPayerPayee(
  obj: any,
  list: any,
  type: string
): boolean {
  var x;
  for (x in list) {
    if (
      list[x].para_id === obj.paraId &&
      list[x].sentence_id === obj.startSentenceId &&
      list[x].type === type
    ) {
      return true;
    }
  }
  return false;
}

export function containsDefinitions(obj: any, list: any) {
  var x;
  for (x in list) {
    if (
      list[x].para_id === obj.paraId &&
      list[x].sentences_id === obj.startSentenceId
    ) {
      return true;
    }
  }
  return false;
}

export function containsPhraseWithWord(obj: any, list: any) {
  var x;
  for (x in list) {
    if (
      list[x].para_id === obj.paraId &&
      list[x].start_sentence_id === obj.startSentenceId &&
      list[x].start_word_id === obj.startWordId &&
      list[x].end_word_id === obj.endWordId
    ) {
      return true;
    }
  }
  return false;
}

export const getClauseObjects = (type: string, data?: any) => {
  switch (type) {
    case "notice": {
      return { notice: [], notice_renewal: [] };
    }
    case "non_compete": {
      return { non_compete: [] };
    }
    case "non_solicitation": {
      return { non_solicitation: [], duration: [] };
    }
    case "insurance": {
      return { insurance: [] };
    }
    case "exclusivity": {
      return { exclusivity: [] };
    }
    case "indemnity": {
      return {
        indemnity: [],
        bi_1: [],
        bi_2: [],
        transaction: [],
        amounts: [],
      };
    }
    case "dispute_resolution": {
      return {
        dispute_resolution: [],
        panel: [],
        venue: [],
        legal_entities: [],
      };
    }
    case "change_of_control": {
      return {
        change_of_control: [],
        definitions: [],
        termination: [],
        payment: [],
        consent: [],
        notice: [],
      };
    }
    case "payment": {
      return {
        payment: [],
        amounts: [],
        timeline: [],
      };
    }
    case "data_breach": {
      return {
        data_breach: [],
        notify: [],
        regulation: [],
      };
    }
    case "events_of_default": {
      return {
        events_of_default: [],
        events: [],
      };
    }
    case "force_majeure": {
      return {
        force_majeure: [],
        fm_events: [],
      };
    }
    case "general_definitions": {
      return {
        general_definitions: [],
      };
    }
    case "governing_law": {
      return {
        governing_law: [],
        jurisdiction: [],
      };
    }
    case "limited_liability": {
      return {
        limited_liability: [],
        amounts: [],
        amount_string: [],
      };
    }
    case "confidentiality": {
      return { confidentiality: [], nature: [], duration: [], c_except: [] };
    }
    case "term": {
      return {
        term: [],
        duration: [],
        end: [],
        start: [],
        effective_date: [],
      };
    }
    case "Change of Control Definition": {
      return {
        ...data,
        definitions: [],
      };
    }
    case "Change of Control Termination": {
      return {
        ...data,
        termination: [],
      };
    }
    case "Change of Control Notice": {
      return {
        ...data,
        notice: [],
      };
    }
    case "Change of Control Consent": {
      return {
        ...data,
        consent: [],
      };
    }
    case "Change of Control Payment": {
      return {
        ...data,
        payment: [],
      };
    }
    case "Auto-Renewal": {
      return {
        ...data,
        auto_renewal: [],
      };
    }
    default: {
      return [];
    }
  }
};

export const getEditedPayload = (diff: any) => {
  return {
    edited_content: {
      update: diff,
    },
  };
};

export const deleteDataFromObligation = (
  level: string,
  addedData: any,
  updatedData: any
) => {
  if (level === "para") {
    let obligation_statements = updatedData?.obligation_statements?.filter(
      (data: any) => data.para_id !== addedData.paraId
    );
    updatedData = {
      ...updatedData,
      obligation_statements,
    };
    return updatedData;
  }
  if (level === "sentence") {
    let obligation_statements = updatedData?.obligation_statements?.filter(
      (data: any) =>
        data.para_id !== addedData.paraId ||
        data.sentence_id !== addedData.sentenceId
    );
    updatedData = {
      ...updatedData,
      obligation_statements,
    };
    return updatedData;
  }
  if (level === "phrase") {
    let obligation_statements = updatedData?.obligation_statements?.filter(
      (data: any) =>
        data.para_id !== addedData.paraId ||
        data.sentence_id !== addedData.startSentenceId
    );
    updatedData = {
      ...updatedData,
      obligation_statements,
    };
    return updatedData;
  }
};

export const getClauseDataFormat = (
  action: string,
  type: ClauseType | string,
  addedData: any,
  updatedData: any,
  sentenceData?: any,
  clauseItem?: any,
  addedDataIndex?: number,
  parentClauseData?: any
) => {
  switch (type) {
    case "notice": {
      const isExist = isSentenceExist(addedData, updatedData?.notice);

      if (!isExist && action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        let notice = updatedData?.notice?.slice() || [];
        notice.push(newAddedData);
        updatedData = {
          ...updatedData,
          notice,
        };
        return updatedData;
      } else if (isExist && action === "remove") {
        let notice = updatedData.notice.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          notice,
        };
        return updatedData;
      }
      return;
    }
    case "non_compete": {
      const isExist = isSentenceExist(addedData, updatedData?.non_compete);
      if (!isExist && action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        let non_compete = updatedData?.non_compete?.slice() || [];
        non_compete.push(newAddedData);
        updatedData = {
          ...updatedData,
          non_compete,
        };
        return updatedData;
      }
      if (isExist && action === "remove") {
        let non_compete = updatedData.non_compete.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        let duration = updatedData.duration?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.start_sentence_id !== addedData.sentence_id
        );

        let territory = updatedData.territory?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.start_sentence_id !== addedData.sentence_id
        );
        updatedData = {
          ...updatedData,
          non_compete,
          duration,
          territory,
        };
        return updatedData;
      }
      return;
    }
    case "Non Compete Duration": {
      let newDurationData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        duration_type_id: addedData.durationTypeId,
        duration_value: addedData.durationValue,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhrase(addedData, updatedData?.duration);

      let duration = updatedData?.duration?.slice() || [];
      if (isPhraseExist) {
        const index = updatedData?.duration.findIndex(
          (data: any) =>
            data.para_id === addedData.paraId ||
            data.sentence_id === addedData.sentence_id
        );
        duration[index] = newDurationData;
      } else {
        duration.push(newDurationData);
      }

      let isExist = containsPresentObject(addedData, updatedData?.non_compete);
      let non_compete = updatedData?.non_compete?.slice() || [];
      if (!isExist) {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let addedNonCompeteData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.startSentenceId,
        };
        non_compete.push(addedNonCompeteData);
      }
      updatedData = {
        ...updatedData,
        non_compete,
        duration,
      };
      return updatedData;
    }
    case "Non Compete Territory": {
      let newTerritoryData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhraseWithWord(
        addedData,
        updatedData?.territory
      );

      let territory = updatedData?.territory?.slice() || [];
      if (!isPhraseExist) {
        territory.push(newTerritoryData);
      }

      let isExist = containsPresentObject(addedData, updatedData?.non_compete);
      let non_compete = updatedData?.non_compete?.slice() || [];
      if (!isExist) {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );

        let addedNonCompeteData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.startSentenceId,
        };
        non_compete.push(addedNonCompeteData);
      }
      updatedData = {
        ...updatedData,
        non_compete,
        territory,
      };
      return updatedData;
    }
    case "payment": {
      const isExist = containsPhrase(addedData, updatedData?.payment);

      if (!isExist && action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          start_sentence_id: addedData.sentenceId,
        };
        let payment = updatedData?.payment?.slice() || [];
        payment.push(newAddedData);
        updatedData = {
          ...updatedData,
          payment,
        };
        return updatedData;
      }
      if (isExist && action === "remove") {
        let payment = updatedData.payment.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.start_sentence_id !== addedData.sentenceId
        );
        let timeline = updatedData.timeline.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.start_sentence_id !== addedData.sentenceId
        );
        let amounts = updatedData.amounts.filter(
          (amount: any) =>
            amount.para_id !== addedData.paraId ||
            amount.start_sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          payment,
          timeline,
          amounts,
        };
        return updatedData;
      }
      return;
    }
    case "Payment Timeline": {
      let newTimelineData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        duration_type_id: addedData.durationTypeId,
        duration_value: addedData.durationValue,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhrase(addedData, updatedData?.timeline);

      let timeline = updatedData?.timeline?.slice() || [];
      if (isPhraseExist) {
        const index = updatedData?.timeline.findIndex(
          (data: any) =>
            data.para_id === addedData.paraId ||
            data.sentence_id === addedData.sentence_id
        );
        timeline[index] = newTimelineData;
      } else {
        timeline.push(newTimelineData);
      }

      let isExist = containsPhrase(addedData, updatedData?.payment);
      let payment = updatedData?.payment?.slice() || [];
      if (!isExist) {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let addedNonCompeteData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          start_sentence: sentence,
          sentence_id: addedData.startSentenceId,
        };
        payment.push(addedNonCompeteData);
      }
      updatedData = {
        ...updatedData,
        payment,
        timeline,
      };
      return updatedData;
    }
    case "Payment Amounts": {
      let newAmountData = {
        currency: addedData.currency,
        end_word_id: addedData.endWordId,
        para_id: addedData.paraId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        start_word_id: addedData.startWordId,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
        total: addedData.total,
        value: addedData.value,
        amountString: addedData.phrase,
      };
      const isAmountExist = containsPhrase(addedData, updatedData?.amounts);

      let amounts = updatedData?.amounts?.slice() || [];
      if (isAmountExist) {
        const index = updatedData?.amounts.findIndex(
          (data: any) =>
            data.para_id === addedData.paraId ||
            data.sentence_id === addedData.sentence_id
        );
        amounts[index] = newAmountData;
      } else {
        amounts.push(newAmountData);
      }

      //update Parent
      let isExist = containsPhrase(addedData, updatedData?.payment);
      let payment = updatedData?.payment?.slice() || [];
      if (!isExist) {
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          start_sentence_id: addedData.startSentenceId,
        };
        payment.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        payment,
        amounts,
      };
      return updatedData;
    }
    case "confidentiality": {
      const isExist = isSentenceExist(addedData, updatedData?.confidentiality);

      if (!isExist && action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        let confidentiality = updatedData?.confidentiality?.slice() || [];
        confidentiality.push(newAddedData);
        updatedData = {
          ...updatedData,
          confidentiality,
        };
        return updatedData;
      }
      if (isExist && action === "remove") {
        let confidentiality = updatedData.confidentiality.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        let duration = updatedData.duration?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.start_sentence_id !== addedData.sentence_id
        );
        let nature = updatedData.nature?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.start_sentence_id !== addedData.sentence_id
        );
        let c_except = updatedData.c_except?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.sentence_id !== addedData.sentence_id
        );

        updatedData = {
          ...updatedData,
          confidentiality,
          duration,
          nature,
          c_except,
        };
        return updatedData;
      }
      return;
    }
    case "Confidentiality Duration": {
      let newDurationData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        duration_type_id: addedData.durationTypeId,
        duration_value: addedData.durationValue,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhrase(addedData, updatedData?.duration);

      let duration = updatedData?.duration?.slice() || [];
      if (isPhraseExist) {
        const index = updatedData?.duration.findIndex(
          (data: any) =>
            data.para_id === addedData.paraId ||
            data.sentence_id === addedData.sentence_id
        );
        duration[index] = newDurationData;
      } else {
        duration.push(newDurationData);
      }

      let isExist = containsPresentObject(
        addedData,
        updatedData?.confidentiality
      );
      let confidentiality = updatedData?.confidentiality?.slice() || [];
      if (!isExist) {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let addedConfidentialityData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.startSentenceId,
        };
        confidentiality.push(addedConfidentialityData);
      }
      updatedData = {
        ...updatedData,
        confidentiality,
        duration,
      };
      return updatedData;
    }
    case "Confidentiality Nature": {
      let newNatureData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhraseWithWord(
        addedData,
        updatedData?.nature
      );

      let nature = updatedData?.nature?.slice() || [];
      if (!isPhraseExist) {
        nature.push(newNatureData);
      }

      let isExist = containsPresentObject(addedData, updatedData?.non_compete);
      let confidentiality = updatedData?.confidentiality?.slice() || [];
      if (!isExist) {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );

        let addedConfidentialityData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.startSentenceId,
        };
        confidentiality.push(addedConfidentialityData);
      }

      updatedData = {
        ...updatedData,
        confidentiality,
        nature,
      };
      return updatedData;
    }
    case "Exception to Confidentiality": {
      if (action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        let exceptionToConfidentiality = updatedData?.c_except?.slice() || [];
        exceptionToConfidentiality.push(newAddedData);

        let isExist = containsPresentObject(
          addedData,
          updatedData?.confidentiality
        );
        let confidentiality = updatedData?.confidentiality?.slice() || [];
        if (!isExist) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData.sentenceId,
            addedData?.rowId,
            addedData?.columnId
          );

          let addconfidentialityData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            sentence_id: addedData.sentenceId,
          };
          confidentiality.push(addconfidentialityData);
        }

        updatedData = {
          ...updatedData,
          c_except: exceptionToConfidentiality,
          confidentiality,
        };
        return updatedData;
      }
      if (action === "remove") {
        let exceptionToConfidentiality = updatedData?.c_except?.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          c_except: exceptionToConfidentiality,
        };
        return updatedData;
      }
      return;
    }
    case "non_solicitation": {
      const isExist = isSentenceExist(addedData, updatedData?.non_solicitation);
      if (!isExist && action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        let non_solicitation = updatedData?.non_solicitation?.slice() || [];
        non_solicitation.push(newAddedData);
        updatedData = {
          ...updatedData,
          non_solicitation,
        };
        return updatedData;
      } else if (action === "remove") {
        let non_solicitation = updatedData.non_solicitation.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        let duration = updatedData.duration?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.start_sentence_id !== addedData.sentence_id
        );

        updatedData = {
          ...updatedData,
          non_solicitation,
          duration,
        };
        return updatedData;
      }
      return;
    }
    case "Non Solicitation Duration": {
      let newDurationData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        duration_type_id: addedData.durationTypeId,
        duration_value: addedData.durationValue,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhrase(addedData, updatedData?.duration);

      let duration = updatedData?.duration?.slice() || [];
      if (isPhraseExist) {
        const index = updatedData?.duration.findIndex(
          (data: any) =>
            data.para_id === addedData.paraId ||
            data.sentence_id === addedData.sentence_id
        );
        duration[index] = newDurationData;
      } else {
        duration.push(newDurationData);
      }

      let isExist = containsPresentObject(
        addedData,
        updatedData?.non_solicitation
      );
      let non_solicitation = updatedData?.non_solicitation?.slice() || [];
      if (!isExist) {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let addedNonSolicitationData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.startSentenceId,
        };
        non_solicitation.push(addedNonSolicitationData);
      }
      updatedData = {
        ...updatedData,
        non_solicitation,
        duration,
      };
      return updatedData;
    }
    case "insurance": {
      if (action === "add") {
        let para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        let insurance = updatedData?.insurance?.slice() || [];
        insurance.push(newAddedData);
        updatedData = {
          ...updatedData,
          insurance,
        };
        return updatedData;
      } else if (action === "remove") {
        let insurance = updatedData.insurance.filter(
          (data: any) => data.para_id !== addedData.paraId
        );
        updatedData = {
          ...updatedData,
          insurance,
        };
        return updatedData;
      }

      return;
    }
    case "governing_law": {
      if (action === "add") {
        let para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        let governing_law = updatedData?.governing_law?.slice() || [];
        governing_law.push(newAddedData);
        updatedData = {
          ...updatedData,
          governing_law,
        };
        return updatedData;
      } else if (action === "remove") {
        let governing_law = updatedData.governing_law.filter(
          (data: any) => data.para_id !== addedData.paraId
        );
        let jurisdiction = updatedData.jurisdiction.filter(
          (data: any) => data.para_id !== addedData.paraId
        );
        updatedData = {
          ...updatedData,
          governing_law,
          jurisdiction,
        };
        return updatedData;
      }
      return;
    }
    case "Jurisdiction": {
      let newJurisdictionData = {
        clean_jurisdiction: addedData.phrase,
        end_sentence_id: addedData.startSentenceId,
        end_word_id: addedData.endWordId,
        jurisdiction: addedData.phrase,
        para_id: addedData.paraId,
        start_sentence_id: addedData.startSentenceId,
        start_word_id: addedData.startWordId,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhraseWithWord(
        addedData,
        updatedData?.jurisdiction
      );
      let jurisdiction = updatedData?.jurisdiction?.slice() || [];
      if (!isPhraseExist) {
        jurisdiction.push(newJurisdictionData);
      }
      let isExist = isParaExist(addedData, updatedData?.governing_law);
      let governing_law = updatedData?.governing_law?.slice() || [];
      if (!isExist) {
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
        };
        governing_law.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        governing_law,
        jurisdiction,
      };
      return updatedData;
    }
    case "exclusivity": {
      const isExist = isSentenceExist(addedData, updatedData?.exclusivity);

      if (!isExist && action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        let exclusivity = updatedData?.exclusivity?.slice() || [];
        exclusivity.push(newAddedData);
        updatedData = {
          ...updatedData,
          exclusivity,
        };
        return updatedData;
      } else if (isExist && action === "remove") {
        let exclusivity = updatedData.exclusivity.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          exclusivity,
        };
        return updatedData;
      }
      return;
    }
    case "force_majeure": {
      if (action === "add") {
        let para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          para: para,
          para_id: addedData.paraId,
          table: [{ row: addedData.rowId, column: addedData.columnId }],
        };
        let force_majeure = updatedData?.force_majeure?.slice() || [];
        force_majeure.push(newAddedData);
        updatedData = {
          ...updatedData,
          force_majeure,
        };
        return updatedData;
      } else if (action === "remove") {
        let force_majeure = updatedData.force_majeure.filter(
          (data: any) => data.para_id !== addedData.paraId
        );
        let fm_events = updatedData.fm_events?.filter(
          (data: any) => data.para_id !== addedData.para_id
        );
        updatedData = {
          ...updatedData,
          force_majeure,
          fm_events,
        };
        return updatedData;
      }
      return;
    }
    case "Events Covered": {
      let newEventData = {
        end_word_id: addedData.endWordId,
        events: addedData.phrase,
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        status: "Approved",
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhraseWithWord(
        addedData,
        updatedData?.fm_events
      );
      let fm_events = updatedData?.fm_events?.slice() || [];
      if (!isPhraseExist) {
        fm_events.push(newEventData);
      }

      let isExist = isParaExist(addedData, updatedData?.force_majeure);
      let force_majeure = updatedData?.force_majeure?.slice() || [];
      if (!isExist) {
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
        };
        force_majeure.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        force_majeure,
        fm_events,
      };
      return updatedData;
    }
    case "limited_liability": {
      const isExist = isSentenceExist(
        addedData,
        updatedData?.limited_liability
      );

      if (!isExist && action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        let limited_liability = updatedData?.limited_liability?.slice() || [];
        limited_liability.push(newAddedData);
        updatedData = {
          ...updatedData,
          limited_liability,
        };
        return updatedData;
      }
      if (isExist && action === "remove") {
        let limited_liability = updatedData.limited_liability.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        let amount_string = updatedData.amount_string?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.sentence_id !== addedData.sentence_id
        );
        let amounts = updatedData.amounts.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.sentence_id !== addedData.sentence_id
        );

        updatedData = {
          ...updatedData,
          limited_liability,
          amount_string,
          amounts,
        };
        return updatedData;
      }
      return;
    }
    case "Limitation Of Liability Amount": {
      let newAddedData = {
        table: [{ row: addedData.rowId, column: addedData.columnId }],
        para_id: addedData.paraId,
        amountString: addedData.phrase,
        sentence_id: addedData.sentenceId,
        end_word_id: addedData.endWordId,
        start_word_id: addedData.startWordId,
      };
      let newAmountData = {
        currency: addedData.currency,
        end_word_id: addedData.endWordId,
        para_id: addedData.paraId,
        sentence_id: addedData.sentenceId,
        start_word_id: addedData.startWordId,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
        total: addedData.total,
        value: addedData.value,
      };
      const isPhraseExist = containsPhrase(
        addedData,
        updatedData?.amount_string
      );
      const isAmountExist = containsPhrase(addedData, updatedData?.amounts);
      let amount_string = updatedData?.amount_string?.slice() || [];
      let amounts = updatedData?.amounts?.slice() || [];
      if (isPhraseExist) {
        const index = updatedData?.amount_string.findIndex(
          (data: any) =>
            data.para_id === addedData.paraId ||
            data.sentence_id === addedData.sentence_id
        );
        amount_string[index] = newAddedData;
      } else {
        amount_string.push(newAddedData);
      }
      if (isAmountExist) {
        const index = updatedData?.amounts.findIndex(
          (data: any) =>
            data.para_id === addedData.paraId ||
            data.sentence_id === addedData.sentence_id
        );
        amounts[index] = newAmountData;
      } else {
        amounts.push(newAmountData);
      }
      let isExist = containsPresentObject(
        addedData,
        updatedData?.limited_liability
      );
      let limited_liability = updatedData?.limited_liability?.slice() || [];
      if (!isExist) {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let addedLiabilityData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        limited_liability.push(addedLiabilityData);
      }
      updatedData = {
        ...updatedData,
        limited_liability,
        amounts,
        amount_string,
      };
      return updatedData;
    }
    case "general_definitions": {
      let sentence = getPara(
        sentenceData,
        addedData.paraId,
        addedData.startSentenceId,
        addedData?.rowId,
        addedData?.columnId
      );
      let newData = {
        para_id: addedData.paraId,
        sentence: sentence,
        end_word_id: addedData.endWordId,
        defined_word: addedData.definedword,
        definition: addedData.phrase,
        start_word_id: addedData.startWordId,
        sentences_id: addedData.startSentenceId,
        phrase: addedData.phrase,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsDefinitions(
        addedData,
        updatedData?.general_definitions
      );
      let general_definitions = updatedData?.general_definitions?.slice() || [];
      if (isPhraseExist) {
        const index = updatedData?.general_definitions.findIndex(
          (data: any) =>
            data.para_id === addedData.paraId ||
            data.sentence_id === addedData.sentence_id
        );
        general_definitions[index] = newData;
      } else {
        general_definitions.push(newData);
      }
      updatedData = {
        ...updatedData,
        general_definitions,
      };
      return updatedData;
    }
    case "term": {
      const isExist = isParaExist(addedData, updatedData?.term);

      if (!isExist && action === "add") {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          pattern: para,
          pattern_type: null,
        };
        let term_data = updatedData?.term?.slice() || [];
        term_data.push(newAddedData);
        updatedData = {
          ...updatedData,
          term: term_data,
        };
        return updatedData;
      }
      if (isExist && action === "remove") {
        let term = updatedData.term.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        let duration = updatedData.duration?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.start_sentence_id !== addedData.sentence_id
        );

        let start = updatedData.start?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.start_sentence_id !== addedData.sentence_id
        );
        let end = updatedData.end?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.start_sentence_id !== addedData.sentence_id
        );
        updatedData = {
          ...updatedData,
          term,
          duration,
          start,
          end,
        };
        return updatedData;
      }
      return;
    }
    case "Term Start Dates": {
      let newData = { ...updatedData };
      let newStartData = {
        date: addedData.date,
        normalized_date: addedData.normalized_date,
        para_id: addedData.paraId,
        table: addedData.table,
      };
      newData = { ...newData, start: [newStartData] };
      let isParaExistInTerm = isParaExist(addedData, updatedData.term);
      let termData = [...updatedData.term];
      if (!isParaExistInTerm) {
        let para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        //todo: need to confirm new keys pattern and pattern type
        let newAddedData = {
          table: addedData.table,
          para_id: addedData.paraId,
          pattern: para,
          pattern_type: null,
        };
        termData = [...termData, newAddedData];
        newData = { ...newData, term: [...termData] };
      }
      return newData;
    }
    case "Term End Dates": {
      let newData = { ...updatedData };
      let newEndData = {
        date: addedData.date,
        normalized_date: addedData.normalized_date,
        para_id: addedData.paraId,
        table: addedData.table,
      };
      newData = { ...newData, end: [newEndData] };
      let isParaExistInTerm = isParaExist(addedData, updatedData.term);
      let termData = [...updatedData.term];
      if (!isParaExistInTerm) {
        let para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        //todo: need to confirm new keys pattern and pattern type
        let newAddedData = {
          table: addedData.table,
          para_id: addedData.paraId,
          pattern: para,
          pattern_type: null,
        };
        termData = [...termData, newAddedData];
        newData = { ...newData, term: [...termData] };
      }
      return newData;
    }
    case "Term Duration": {
      let newData = { ...updatedData };
      let newDurationData = {
        date: addedData.date,
        normalized_date: addedData.normalized_date,
        para_id: addedData.paraId,
        table: addedData.table,
      };
      newData = { ...newData, duration: [newDurationData] };
      let isParaExistInTerm = isParaExist(addedData, updatedData.term);
      let termData = [...updatedData.term];
      if (!isParaExistInTerm) {
        let para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        //todo: need to confirm new keys pattern and pattern type
        let newAddedData = {
          table: addedData.table,
          para_id: addedData.paraId,
          pattern: para,
          pattern_type: null,
        };
        termData = [...termData, newAddedData];
        newData = { ...newData, term: [...termData] };
      }
      return newData;
    }
    case "data_breach": {
      if (action === "add") {
        let para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          para_id: addedData.paraId,
          para: para,
          table: [{ row: addedData.rowId, column: addedData.columnId }],
        };
        let data_breach = updatedData?.data_breach?.slice() || [];
        data_breach.push(newAddedData);
        updatedData = {
          ...updatedData,
          data_breach,
        };
        return updatedData;
      }
      if (action === "remove") {
        let data_breach = updatedData.data_breach.filter(
          (data: any) => data.para_id !== addedData.paraId
        );

        let notify = updatedData.notify?.filter(
          (data: any) => data.para_id !== addedData.para_id
        );

        let regulation = updatedData.regulation;
        if (regulation?.para_id === addedData.para_id) {
          regulation = null;
        }

        updatedData = {
          ...updatedData,
          data_breach,
          notify,
          regulation,
        };
        return updatedData;
      }
      return;
    }
    case "Data Breach Notify": {
      let para = getPara(
        sentenceData,
        addedData.paraId,
        addedData?.rowId,
        addedData?.columnId
      );

      let newNotifyData = {
        notify: [{ ext_: addedData.phrase }],
        para: para,
        end_word_id: addedData.endWordId,
        para_id: addedData.paraId,
        sentence_id: addedData.startSentenceId,
        start_word_id: addedData.startWordId,
      };
      const isPhraseExist = containsPhraseWithWord(
        addedData,
        updatedData?.notify
      );
      let notify = updatedData?.notify?.slice() || [];
      if (!isPhraseExist) {
        notify.push(newNotifyData);
      }

      let isExist = isParaExist(addedData, updatedData?.data_breach);
      let data_breach = updatedData?.data_breach?.slice() || [];
      if (!isExist) {
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
        };
        data_breach.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        data_breach,
        notify,
      };
      return updatedData;
    }
    case "Data Breach Regulation": {
      let newRegulationData = {
        extraction: [{ ext: addedData.phrase }],
        para_id: addedData.paraId,
        end_word_id: addedData.endWordId,
        sentence_id: addedData.startSentenceId,
        start_word_id: addedData.startWordId,
      };
      let regulation = newRegulationData;

      let isExist = isParaExist(addedData, updatedData?.data_breach);
      let data_breach = updatedData?.data_breach?.slice() || [];
      if (!isExist) {
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
        };
        data_breach.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        data_breach,
        regulation,
      };
      return updatedData;
    }
    case "indemnity": {
      if (action === "add") {
        let para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        let indemnity = updatedData?.indemnity?.slice() || [];
        indemnity.push(newAddedData);
        updatedData = {
          ...updatedData,
          indemnity,
        };
        return updatedData;
      } else if (action === "remove") {
        let indemnity = updatedData.indemnity.filter(
          (data: any) => data.para_id !== addedData.paraId
        );
        let amounts = updatedData.amounts?.filter(
          (data: any) => data.para_id !== addedData.para_id
        );

        let transaction = updatedData.transaction?.filter(
          (data: any) => data.para_id !== addedData.para_id
        );

        let bi_1 = updatedData.bi_1.filter(
          (data: any) => data.para_id !== addedData.para_id
        );

        let bi_2 = updatedData.bi_2.filter(
          (data: any) => data.para_id !== addedData.para_id
        );
        updatedData = {
          ...updatedData,
          indemnity,
          amounts,
          transaction,
          bi_1,
          bi_2,
        };
        return updatedData;
      }
      return;
    }
    case "Indemnity/Reimbursements/Costs Amount": {
      let newAmountData = {
        currency: addedData.currency,
        end_word_id: addedData.endWordId,
        para_id: addedData.paraId,
        sentence_id: addedData.sentenceId,
        start_word_id: addedData.startWordId,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
        total: addedData.total,
        value: addedData.value,
        amountString: addedData.phrase,
      };
      const isAmountExist = containsPhrase(addedData, updatedData?.amounts);

      let amounts = updatedData?.amounts?.slice() || [];
      if (isAmountExist) {
        const index = updatedData?.amounts.findIndex(
          (data: any) =>
            data.para_id === addedData.paraId ||
            data.sentence_id === addedData.sentence_id
        );
        amounts[index] = newAmountData;
      } else {
        amounts.push(newAmountData);
      }

      //update Parent
      let isExist = isParaExist(addedData, updatedData?.indemnity);
      let indemnity = updatedData?.indemnity?.slice() || [];
      if (!isExist) {
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
        };
        indemnity.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        indemnity,
        amounts,
      };
      return updatedData;
    }
    case "Indemnity/Reimbursements/Costs Extent of Cost": {
      let newExtentData = {
        end_word_id: addedData.endWordId,
        para_id: addedData.paraId,
        sentence_id: addedData.startSentenceId,
        start_word_id: addedData.startWordId,
        string: addedData.phrase,
      };
      const isPhraseExist = containsPresentObject(addedData, updatedData.bi_1);
      const bi_1 = updatedData?.bi_1?.slice() || [];
      if (!isPhraseExist) {
        bi_1.push(newExtentData);
      }

      //update Parent
      let isExist = isParaExist(addedData, updatedData?.indemnity);
      let indemnity = updatedData?.indemnity?.slice() || [];
      if (!isExist) {
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
        };
        indemnity.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        indemnity,
        bi_1,
      };
      return updatedData;
    }
    case "Indemnity/Reimbursements/Costs Triggering Event": {
      let newExtentData = {
        end_word_id: addedData.endWordId,
        para_id: addedData.paraId,
        sentence_id: addedData.sentenceId,
        start_word_id: addedData.startWordId,
        string: addedData.phrase,
      };
      const isPhraseExist = containsPresentObject(addedData, updatedData.bi_2);
      const bi_2 = updatedData?.bi_2?.slice() || [];
      if (!isPhraseExist) {
        bi_2.push(newExtentData);
      }
      //update Parent
      let isExist = isParaExist(addedData, updatedData?.indemnity);
      let indemnity = updatedData?.indemnity?.slice() || [];
      if (!isExist) {
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
        };
        indemnity.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        indemnity,
        bi_2,
      };
      return updatedData;
    }
    case "Indemnity/Reimbursements/Costs Payer": {
      let newExtentData = {
        end_word_id: addedData.endWordId,
        para_id: addedData.paraId,
        sentence_id: addedData.sentenceId,
        start_word_id: addedData.startWordId,
        string: addedData.phrase,
        type: "payer",
      };
      const isPhraseExist = containIndemnityPayerPayee(
        addedData,
        updatedData.transaction,
        "payer"
      );
      const transaction = updatedData?.transaction?.slice() || [];
      if (!isPhraseExist) {
        transaction.push(newExtentData);
      }
      //update Parent
      let isExist = isParaExist(addedData, updatedData?.indemnity);
      let indemnity = updatedData?.indemnity?.slice() || [];
      if (!isExist) {
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
        };
        indemnity.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        indemnity,
        transaction,
      };
      return updatedData;
    }
    case "Indemnity/Reimbursements/Costs Payee": {
      let newExtentData = {
        end_word_id: addedData.endWordId,
        para_id: addedData.paraId,
        sentence_id: addedData.sentenceId,
        start_word_id: addedData.startWordId,
        string: addedData.phrase,
        type: "payee",
      };
      const isPhraseExist = containIndemnityPayerPayee(
        addedData,
        updatedData.transaction,
        "payee"
      );
      const transaction = updatedData?.transaction?.slice() || [];
      if (!isPhraseExist) {
        transaction.push(newExtentData);
      }
      //update Parent
      let isExist = isParaExist(addedData, updatedData?.indemnity);
      let indemnity = updatedData?.indemnity?.slice() || [];
      if (!isExist) {
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
        };
        indemnity.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        indemnity,
        transaction,
      };
      return updatedData;
    }
    case "Indemnity/Reimbursements/Costs Payer": {
      let newExtentData = {
        end_word_id: addedData.endWordId,
        para_id: addedData.paraId,
        sentence_id: addedData.sentenceId,
        start_word_id: addedData.startWordId,
        string: addedData.phrase,
        type: "payer",
      };
      const isPhraseExist = containIndemnityPayerPayee(
        addedData,
        updatedData.bi_2,
        "payer"
      );
      const transaction = updatedData?.transaction?.slice() || [];
      if (!isPhraseExist) {
        transaction.push(newExtentData);
      }
      //update Parent
      let isExist = isParaExist(addedData, updatedData?.indemnity);
      let indemnity = updatedData?.indemnity?.slice() || [];
      if (!isExist) {
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
        };
        indemnity.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        indemnity,
        transaction,
      };
      return updatedData;
    }
    case "renewal": {
      const isExist = isParaExist(addedData, updatedData?.renewal);

      if (!isExist && action === "add") {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        let renewal = updatedData?.renewal?.slice() || [];
        renewal.push(newAddedData);
        updatedData = {
          ...updatedData,
          renewal,
        };
        return updatedData;
      }
      if (isExist && action === "remove") {
        let renewal = updatedData.renewal.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        let renewal_duration = updatedData.renewal_duration?.filter(
          (data: any) => data.para_id !== addedData.para_id
        );

        let reference_date = updatedData.reference_date?.filter(
          (data: any) => data.para_id !== addedData.para_id
        );
        let auto_renewal = updatedData.auto_renewal?.filter(
          (data: any) => data.para_id !== addedData.para_id
        );

        let notice_auto_renewal = updatedData.notice_auto_renewal?.filter(
          (data: any) => data.para_id !== addedData.para_id
        );
        updatedData = {
          ...updatedData,
          renewal,
          auto_renewal,
          notice_auto_renewal,
          reference_date,
          renewal_duration,
        };
        return updatedData;
      }
      return updatedData;
    }
    case "Renewal clause": {
      const isExist = isParaExist(addedData, updatedData?.renewal);

      if (!isExist && action === "add") {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        let renewal = updatedData?.renewal?.slice() || [];
        renewal.push(newAddedData);
        updatedData = {
          ...updatedData,
          renewal,
        };
        return updatedData;
      }
      if (isExist && action === "remove") {
        let renewal = updatedData.renewal.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          renewal,
        };
        return updatedData;
      }
      return updatedData;
    }
    case "Auto-Renewal": {
      const isExist = isParaExist(addedData, updatedData?.auto_renewal);
      const renewal = [...updatedData?.renewal];
      if (!isExist && action === "add") {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        let auto_renewal = updatedData?.auto_renewal?.slice() || [];
        const isParaExistInParent = isParaExist(
          addedData,
          updatedData?.renewal
        );
        if (!isParaExistInParent) {
          let newParentObj = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
          };
          renewal.push(newParentObj);
        }
        auto_renewal.push(newAddedData);
        updatedData = {
          ...updatedData,
          auto_renewal,
          renewal,
        };
        return updatedData;
      }
      if (isExist && action === "remove") {
        let renewal = updatedData.renewal.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          renewal,
        };
        return updatedData;
      }
      return updatedData;
    }
    case "Renewal Notice Duration": {
      let newDurationData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        duration_type_id: addedData.durationTypeId,
        duration_value: addedData.durationValue,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhrase(
        addedData,
        updatedData?.renewal_duration
      );

      let renewal_duration = updatedData?.renewal_duration?.slice() || [];
      if (isPhraseExist) {
        const index = updatedData?.renewal_duration.findIndex(
          (data: any) =>
            data.para_id === addedData.paraId ||
            data.sentence_id === addedData.sentence_id
        );
        renewal_duration[index] = newDurationData;
      } else {
        renewal_duration.push(newDurationData);
      }
      let isExist = isParaExist(addedData, updatedData?.renewal);
      let renewal = updatedData?.renewal?.slice() || [];
      if (!isExist) {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let addedRenewalData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
          sentence_id: addedData.startSentenceId,
        };
        renewal.push(addedRenewalData);
      }
      updatedData = {
        ...updatedData,
        renewal,
        renewal_duration,
      };
      return updatedData;
    }
    case "Notice for Auto-Renewal": {
      let newAutoRenewalData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
        date: addedData.date,
      };
      const isPhraseExist = containsPhraseWithWord(
        addedData,
        updatedData?.notice_auto_renewal
      );

      let notice_auto_renewal = updatedData?.notice_auto_renewal?.slice() || [];
      if (!isPhraseExist) {
        notice_auto_renewal.push(newAutoRenewalData);
      }
      let isExist = isParaExist(addedData, updatedData?.renewal);
      let renewal = updatedData?.renewal?.slice() || [];
      if (!isExist) {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let addedRenewalData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
          sentence_id: addedData.startSentenceId,
        };
        renewal.push(addedRenewalData);
      }
      updatedData = {
        ...updatedData,
        renewal,
        notice_auto_renewal,
      };
      return updatedData;
    }
    case "Renewal Notice Reference Date": {
      let newNoticeRenewalData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        phrase: addedData.phrase,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
        date: addedData.date,
      };
      const isPhraseExist = containsPhraseWithWord(
        addedData,
        updatedData?.reference_date
      );

      let reference_date = updatedData?.reference_date?.slice() || [];
      if (!isPhraseExist) {
        reference_date.push(newNoticeRenewalData);
      }
      let isExist = isParaExist(addedData, updatedData?.renewal);
      let renewal = updatedData?.renewal?.slice() || [];
      if (!isExist) {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData.startSentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let addedRenewalData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
          sentence_id: addedData.startSentenceId,
        };
        renewal.push(addedRenewalData);
      }
      updatedData = {
        ...updatedData,
        renewal,
        reference_date,
      };
      return updatedData;
    }
    case "events_of_default": {
      if (action === "add") {
        let para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          para: para,
          para_id: addedData.paraId,
          type: "para",
          table: [{ row: addedData.rowId, column: addedData.columnId }],
        };
        let events_of_default = updatedData?.events_of_default?.slice() || [];
        events_of_default.push(newAddedData);
        updatedData = {
          ...updatedData,
          events_of_default,
        };
        return updatedData;
      } else if (action === "remove") {
        let events_of_default = updatedData.events_of_default.filter(
          (data: any) => data.para_id !== addedData.paraId
        );
        let events = updatedData.events?.filter(
          (data: any) => data.para_id !== addedData.para_id
        );
        updatedData = {
          ...updatedData,
          events_of_default,
          events,
        };
        return updatedData;
      }
      return;
    }
    case "Events Of Default Event": {
      let newEventData = {
        end_word_id: addedData.endWordId,
        events: addedData.phrase,
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        status: "Approved",
        table: [{ row: addedData.rowId, column: addedData.columnId }],
        eventId: addedData.eventId,
        sentence_id: addedData.sentenceId,
        id: addedData.id,
        eventHighlightId: addedData.eventHighlightId,
        eventName: addedData.eventName,
      };
      const isPhraseExist = containsPhraseWithWord(
        addedData,
        updatedData?.events
      );
      let events = updatedData?.events?.slice() || [];
      if (!isPhraseExist) {
        events.push(newEventData);
      }

      let isExist = isParaExist(addedData, updatedData?.events_of_default);
      let events_of_default = updatedData?.events_of_default?.slice() || [];
      if (!isExist) {
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
        };
        events_of_default.push(newAddedData);
      }
      updatedData = {
        ...updatedData,
        events_of_default,
        events,
      };
      return updatedData;
    }
    case "change_of_control": {
      const isExist = isSentenceExist(
        addedData,
        updatedData?.change_of_control
      );

      if (!isExist && action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        let change_of_control = updatedData?.change_of_control?.slice() || [];
        change_of_control.push(newAddedData);
        updatedData = {
          ...updatedData,
          change_of_control,
        };
        return updatedData;
      }
      if (isExist && action === "remove") {
        let change_of_control = updatedData.change_of_control.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );

        let definitions = updatedData.definitions?.filter(
          (data: any) => data.para_id !== addedData.para_id
        );

        let notice = updatedData.notice?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.sentence_id !== addedData.sentence_id
        );

        let termination = updatedData.termination?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.sentence_id !== addedData.sentence_id
        );

        let consent = updatedData.consent?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.sentence_id !== addedData.sentence_id
        );

        let payment = updatedData.payment?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.sentence_id !== addedData.sentence_id
        );
        updatedData = {
          ...updatedData,
          change_of_control,
          definitions,
          termination,
          consent,
          payment,
          notice,
        };
        return updatedData;
      }
      return;
    }
    case "Change of Control Definition": {
      if (action === "add") {
        let para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        let definitions = updatedData?.definitions?.slice() || [];
        definitions.push(newAddedData);

        let isExist = containsPresentObject(
          addedData,
          updatedData?.change_of_control
        );
        let change_of_control = updatedData?.change_of_control?.slice() || [];
        if (!isExist) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData.sentenceId,
            addedData?.rowId,
            addedData?.columnId
          );

          let addChangeOfControlData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            sentence_id: addedData.sentenceId || -1,
          };
          change_of_control.push(addChangeOfControlData);
        }
        updatedData = {
          ...updatedData,
          definitions,
          change_of_control,
        };
        return updatedData;
      } else if (action === "remove") {
        let definitions = updatedData.definitions.filter(
          (data: any) => data.para_id !== addedData.paraId
        );
        updatedData = {
          ...updatedData,
          definitions,
        };
        return updatedData;
      }
      return;
    }
    case "Change of Control Termination": {
      if (action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        let termination = updatedData?.termination?.slice() || [];
        termination.push(newAddedData);

        let isExist = containsPresentObject(
          addedData,
          updatedData?.change_of_control
        );
        let change_of_control = updatedData?.change_of_control?.slice() || [];
        if (!isExist) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData.sentenceId,
            addedData?.rowId,
            addedData?.columnId
          );

          let addChangeOfControlData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            sentence_id: addedData.sentenceId,
          };
          change_of_control.push(addChangeOfControlData);
        }

        updatedData = {
          ...updatedData,
          termination,
          change_of_control,
        };
        return updatedData;
      }
      if (action === "remove") {
        let termination = updatedData?.termination?.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          termination,
        };
        return updatedData;
      }
      return;
    }
    case "Change of Control Consent": {
      if (action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        let consent = updatedData?.consent?.slice() || [];
        consent.push(newAddedData);

        let isExist = containsPresentObject(
          addedData,
          updatedData?.change_of_control
        );
        let change_of_control = updatedData?.change_of_control?.slice() || [];
        if (!isExist) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData.sentenceId,
            addedData?.rowId,
            addedData?.columnId
          );

          let addChangeOfControlData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            sentence_id: addedData.sentenceId,
          };
          change_of_control.push(addChangeOfControlData);
        }

        updatedData = {
          ...updatedData,
          consent,
          change_of_control,
        };
        return updatedData;
      }
      if (action === "remove") {
        let consent = updatedData?.consent?.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          consent,
        };
        return updatedData;
      }
      return;
    }
    case "Change of Control Notice": {
      if (action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        let notice = updatedData?.notice?.slice() || [];
        notice.push(newAddedData);

        let isExist = containsPresentObject(
          addedData,
          updatedData?.change_of_control
        );
        let change_of_control = updatedData?.change_of_control?.slice() || [];
        if (!isExist) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData.sentenceId,
            addedData?.rowId,
            addedData?.columnId
          );

          let addChangeOfControlData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            sentence_id: addedData.sentenceId,
          };
          change_of_control.push(addChangeOfControlData);
        }

        updatedData = {
          ...updatedData,
          notice,
          change_of_control,
        };
        return updatedData;
      }
      if (action === "remove") {
        let notice = updatedData?.notice?.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          notice,
        };
        return updatedData;
      }
      return;
    }
    case "Change of Control Payment": {
      if (action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        let payment = updatedData?.payment?.slice() || [];
        payment.push(newAddedData);

        let isExist = containsPresentObject(
          addedData,
          updatedData?.change_of_control
        );
        let change_of_control = updatedData?.change_of_control?.slice() || [];
        if (!isExist) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData.sentenceId,
            addedData?.rowId,
            addedData?.columnId
          );

          let addChangeOfControlData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            sentence_id: addedData.sentenceId,
          };
          change_of_control.push(addChangeOfControlData);
        }

        updatedData = {
          ...updatedData,
          payment,
          change_of_control,
        };
        return updatedData;
      }
      if (action === "remove") {
        let payment = updatedData?.payment?.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          payment,
        };
        return updatedData;
      }
      return;
    }
    case "dispute_resolution": {
      if (action === "add") {
        let para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
          modes: [],
        };
        let dispute_resolution = updatedData?.dispute_resolution?.slice() || [];
        dispute_resolution.push(newAddedData);
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      } else if (action === "remove") {
        let dispute_resolution = updatedData.dispute_resolution.filter(
          (data: any) => data.para_id !== addedData.paraId
        );
        let venue = updatedData.venue?.filter(
          (data: any) => data.para_id !== addedData.para_id
        );

        let panel = updatedData.panel?.filter(
          (data: any) => data.para_id !== addedData.para_id
        );

        let legal_entities = updatedData.legal_entities?.filter(
          (data: any) => data.para_id !== addedData.para_id
        );

        updatedData = {
          ...updatedData,
          dispute_resolution,
          venue,
          panel,
          legal_entities,
        };
        return updatedData;
      }
      return;
    }
    case "Dispute Resolution Arbitration": {
      if (action === "add") {
        let dispute_resolution = updatedData?.dispute_resolution?.slice() || [];

        let isExist = isParaExist(addedData, dispute_resolution);
        if (isExist) {
          dispute_resolution.map((data: any) => {
            if (data.para_id === addedData.paraId) {
              if (data.modes) {
                data.modes.push({ key: "Arbitration" });
              } else {
                data.modes = [{ key: "Arbitration" }];
              }
            }
          });
        } else {
          let para = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );
          let newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
            modes: [{ key: "Arbitration" }],
          };
          dispute_resolution.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      if (action === "remove") {
        let disputeResolution = updatedData?.dispute_resolution?.slice() || [];
        const dispute_resolution = disputeResolution.map((data: any) => {
          if (data.para_id === addedData.para_id) {
            let modes = data.modes.filter(
              (item: any) => item.key !== "Arbitration"
            );
            data.modes = modes;
          }
        });
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      return;
    }
    case "Dispute Resolution Conciliation": {
      if (action === "add") {
        let dispute_resolution = updatedData?.dispute_resolution?.slice() || [];
        let isExist = isParaExist(addedData, dispute_resolution);
        if (isExist) {
          dispute_resolution.map((data: any) => {
            if (data.para_id === addedData.paraId) {
              if (data.modes) {
                data.modes.push({ key: "Conciliation" });
              } else {
                data.modes = [{ key: "Conciliation" }];
              }
            }
          });
        } else {
          let para = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );
          let newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
            modes: [{ key: "Conciliation" }],
          };
          dispute_resolution.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      if (action === "remove") {
        let disputeResolution = updatedData?.dispute_resolution?.slice() || [];
        const dispute_resolution = disputeResolution.map((data: any) => {
          if (data.para_id === addedData.para_id) {
            let modes = data.modes.filter(
              (item: any) => item.key !== "Conciliation"
            );
            data.modes = modes;
          }
        });
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      return;
    }
    case "Dispute Resolution Mediation": {
      if (action === "add") {
        let dispute_resolution = updatedData?.dispute_resolution?.slice() || [];
        let isExist = isParaExist(addedData, dispute_resolution);
        if (isExist) {
          dispute_resolution.map((data: any) => {
            if (data.para_id === addedData.paraId) {
              if (data.modes) {
                data.modes.push({ key: "Mediation" });
              } else {
                data.modes = [{ key: "Mediation" }];
              }
            }
          });
        } else {
          let para = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );
          let newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
            modes: [{ key: "Mediation" }],
          };
          dispute_resolution.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      if (action === "remove") {
        let disputeResolution = updatedData?.dispute_resolution?.slice() || [];
        const dispute_resolution = disputeResolution.map((data: any) => {
          if (data.para_id === addedData.para_id) {
            let modes = data.modes.filter(
              (item: any) => item.key !== "Mediation"
            );
            data.modes = modes;
          }
        });
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      return;
    }
    case "Dispute Resolution Negotiation": {
      if (action === "add") {
        let dispute_resolution = updatedData?.dispute_resolution?.slice() || [];
        let isExist = isParaExist(addedData, dispute_resolution);
        if (isExist) {
          dispute_resolution.map((data: any) => {
            if (data.para_id === addedData.paraId) {
              if (data.modes) {
                data.modes.push({ key: "Negotiation" });
              } else {
                data.modes = [{ key: "Negotiation" }];
              }
            }
          });
        } else {
          let para = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );
          let newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
            modes: [{ key: "Negotiation" }],
          };
          dispute_resolution.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      if (action === "remove") {
        let disputeResolution = updatedData?.dispute_resolution?.slice() || [];
        const dispute_resolution = disputeResolution.map((data: any) => {
          if (data.para_id === addedData.para_id) {
            let modes = data.modes.filter(
              (item: any) => item.key !== "Negotiation"
            );
            data.modes = modes;
          }
        });
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      return;
    }
    case "Dispute Resolution Others": {
      if (action === "add") {
        let dispute_resolution = updatedData?.dispute_resolution?.slice() || [];
        let isExist = isParaExist(addedData, dispute_resolution);
        if (isExist) {
          dispute_resolution.map((data: any) => {
            if (data.para_id === addedData.paraId) {
              if (data.modes) {
                data.modes.push({ key: "Others" });
              } else {
                data.modes = [{ key: "Others" }];
              }
            }
          });
        } else {
          let para = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );
          let newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            para: para,
            modes: [{ key: "Others" }],
          };
          dispute_resolution.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      if (action === "remove") {
        let disputeResolution = updatedData?.dispute_resolution?.slice() || [];
        const dispute_resolution = disputeResolution.map((data: any) => {
          if (data.para_id === addedData.para_id) {
            let modes = data.modes.filter((item: any) => item.key !== "Others");
            data.modes = modes;
          }
        });
        updatedData = {
          ...updatedData,
          dispute_resolution,
        };
        return updatedData;
      }
      return;
    }
    case "Dispute Resolution Panel": {
      let newPanelData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        capture: addedData.phrase,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhraseWithWord(
        addedData,
        updatedData?.panel
      );
      let panel = updatedData?.panel?.slice() || [];
      if (!isPhraseExist) {
        panel.push(newPanelData);
      }
      let isExist = isParaExist(addedData, updatedData?.dispute_resolution);
      let dispute_resolution = updatedData?.dispute_resolution?.slice() || [];
      if (!isExist) {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );

        let addedDisputeResolution = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        dispute_resolution.push(addedDisputeResolution);
      }
      updatedData = {
        ...updatedData,
        dispute_resolution,
        panel,
      };
      return updatedData;
    }
    case "Dispute Resolution Venue": {
      let newVenueData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        capture: addedData.phrase,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhraseWithWord(
        addedData,
        updatedData?.venue
      );
      let venue = updatedData?.venue?.slice() || [];
      if (!isPhraseExist) {
        venue.push(newVenueData);
      }
      let isExist = isParaExist(addedData, updatedData?.dispute_resolution);
      let dispute_resolution = updatedData?.dispute_resolution?.slice() || [];
      if (!isExist) {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );

        let addedDisputeResolution = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        dispute_resolution.push(addedDisputeResolution);
      }
      updatedData = {
        ...updatedData,
        dispute_resolution,
        venue,
      };
      return updatedData;
    }
    case "Dispute Resolution Act/Statute": {
      let newActData = {
        para_id: addedData.paraId,
        start_word_id: addedData.startWordId,
        end_word_id: addedData.endWordId,
        start_sentence_id: addedData.startSentenceId,
        end_sentence_id: addedData.endSentenceId,
        capture: addedData.phrase,
        table: [{ row: addedData.rowId, column: addedData.columnId }],
      };
      const isPhraseExist = containsPhraseWithWord(
        addedData,
        updatedData?.legal_entities
      );
      let legal_entities = updatedData?.legal_entities?.slice() || [];
      if (!isPhraseExist) {
        legal_entities.push(newActData);
      }
      let isExist = isParaExist(addedData, updatedData?.dispute_resolution);
      let dispute_resolution = updatedData?.dispute_resolution?.slice() || [];
      if (!isExist) {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );

        let addedDisputeResolution = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        dispute_resolution.push(addedDisputeResolution);
      }
      updatedData = {
        ...updatedData,
        dispute_resolution,
        legal_entities,
      };
      return updatedData;
    }
    case "obligation_statements": {
      const isExist = isSentenceExist(
        addedData,
        updatedData?.obligation_statements
      );
      if (!isExist && action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        let obligation_statements =
          updatedData?.obligation_statements?.slice() || [];
        obligation_statements.push(newAddedData);

        updatedData = {
          ...updatedData,
          obligation_statements,
        };
        return updatedData;
      }
      if (isExist && action === "remove") {
        let obligation_statements = updatedData.obligation_statements.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          obligation_statements,
        };
        return updatedData;
      }
      return;
    }
    case "Consent Authority": {
      if (action === "add") {
        let consent_regulatory_bi =
          updatedData.consent_regulatory_bi?.slice() || [];
        const sentence = getPara(
          sentenceData,
          clauseItem.para_id,
          clauseItem.sentence_id,
          clauseItem.table && clauseItem.table[0].row,
          clauseItem.table && clauseItem.table[0].column
        );
        let existingParaId = -1;
        for (let i = 0; i < consent_regulatory_bi.length; i++) {
          if (
            consent_regulatory_bi[i].para_id === clauseItem.para_id &&
            consent_regulatory_bi[i].sentence_id === clauseItem.sentence_id
          ) {
            existingParaId = i;
            if (addedDataIndex === 0) {
              consent_regulatory_bi[existingParaId].extraction = [];
              if (
                consent_regulatory_bi[existingParaId]?.extraction?.length ===
                  0 &&
                addedData === null
              ) {
                consent_regulatory_bi.splice(existingParaId, 1);
                let consent = updatedData?.consent?.slice() || [];
                for (let i = 0; i < consent.length; i++) {
                  if (
                    consent[i].para_id === clauseItem.para_id &&
                    consent[i].sentence_id === clauseItem.sentence_id
                  ) {
                    consent[i].type = "Non-Regulatory";
                  }
                }
              }
            }
          }
        }
        if (addedData !== null) {
          if (existingParaId === -1) {
            let extractionAdded: any[] = [];
            if (addedData.entity) {
              extractionAdded = [
                {
                  start_word_id: -1,
                  end_word_id: -1,
                  entity: addedData.entity,
                },
              ];
            } else if (addedData.phrase) {
              extractionAdded = [
                {
                  start_word_id: -1,
                  end_word_id: -1,
                  entity: addedData.phrase,
                },
              ];
            }
            let newAddedData = {
              table: [{ row: addedData.rowId, column: addedData.columnId }],
              para_id: clauseItem.para_id,
              sentence: sentence,
              sentence_id: clauseItem.sentence_id,
              extraction: extractionAdded,
            };

            let consent = updatedData?.consent?.slice() || [];
            for (let i = 0; i < consent.length; i++) {
              if (
                consent[i].para_id === clauseItem.para_id &&
                consent[i].sentence_id === clauseItem.sentence_id
              ) {
                consent[i].type = "Regulatory";
              }
            }
            consent_regulatory_bi.push(newAddedData);
            updatedData = {
              ...updatedData,
              consent,
              consent_regulatory_bi,
            };
          } else {
            let extraction: any = {};
            if (addedData.entity) {
              extraction = {
                start_word_id: -1,
                end_word_id: -1,
                entity: addedData.entity,
              };
            } else if (addedData.phrase) {
              extraction = {
                start_word_id: -1,
                end_word_id: -1,
                entity: addedData.phrase,
              };
            }
            updatedData.consent_regulatory_bi[existingParaId].extraction.push(
              extraction
            );
          }
        }

        return updatedData;
      }
      return;
    }
    case "Consent Clause": {
      if (action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
          type: "Non-Regulatory",
        };
        let consent = updatedData?.consent?.slice() || [];
        consent.push(newAddedData);
        updatedData = {
          ...updatedData,
          consent,
        };
        return updatedData;
      }
      if (action === "remove") {
        let consent = updatedData.consent.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          consent,
        };
        return updatedData;
      }
      return;
    }
    case "Consent Regulatory": {
      if (action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let existingParaId = -1;
        for (let i = 0; i < updatedData.consent.length; i++) {
          if (
            updatedData.consent[i].para_id === clauseItem.para_id &&
            updatedData.consent[i].sentence_id === clauseItem.sentence_id
          ) {
            existingParaId = i;
          }
        }
        let consent = updatedData?.consent?.slice() || [];
        consent[existingParaId].type = addedData.typestring;
        let consent_regulatory_bi =
          updatedData?.consent_regulatory_bi?.slice() || [];
        for (let i = 0; i < consent_regulatory_bi.length; i++) {
          if (
            consent_regulatory_bi[i].para_id === clauseItem.para_id &&
            consent_regulatory_bi[i].sentence_id === clauseItem.sentence_id
          ) {
            if (addedData.typestring === "Non-Regulatory") {
              updatedData.consent_regulatory_bi.splice(i, 1);
            }
          }
        }
        updatedData = {
          ...updatedData,
          consent,
        };
        return updatedData;
      }
      if (action === "remove") {
        let consent = updatedData.consent.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          consent,
        };
        return updatedData;
      }
      return;
    }
    case "Subletting/Assignment": {
      if (action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence_id: addedData.sentenceId,
          sentence: sentence,
        };
        let subletting = updatedData?.subletting?.slice() || [];

        subletting.push(newAddedData);

        updatedData = {
          ...updatedData,
          subletting,
        };
        return updatedData;
      }
      if (action === "remove") {
        let subletting = updatedData.subletting.filter(
          (data: any) => data.para_id !== addedData.paraId
        );
        let savedRequired = updatedData.notice?.required;
        let notice = updatedData.notice?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.sentence_id !== addedData.sentence_id
        );
        if (savedRequired) {
          notice.required = savedRequired;
        }
        if (notice?.length === 0) {
          notice.required = false;
        }

        savedRequired = updatedData.consent?.required;
        let consent = updatedData.consent?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.sentence_id !== addedData.sentence_id
        );
        if (savedRequired) {
          consent.required = savedRequired;
        }
        if (consent?.length === 0) {
          consent.required = false;
        }
        updatedData = {
          ...updatedData,
          subletting,
          notice,
          consent,
        };
        return updatedData;
      }
      return;
    }
    case "Subletting/Assignment Notice Info": {
      if (action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence_id: addedData.sentenceId,
          sentence: sentence,
        };
        let notice = updatedData?.notice?.slice() || [];

        notice.required = addedData.requirement;
        if (notice.required === true) {
          let isExistsInNotice = false;
          for (let i = 0; i < notice.length; i++) {
            if (
              notice[i].para_id === newAddedData.para_id &&
              notice[i].sentence_id === newAddedData.sentence_id
            ) {
              isExistsInNotice = true;
            }
          }
          if (!isExistsInNotice) {
            notice.push(newAddedData);
            updatedData = {
              ...updatedData,
              notice,
            };
          }
          let subletting = updatedData?.subletting?.slice() || [];
          let isExist = isParaExist(addedData, updatedData?.subletting);

          if (!isExist) {
            subletting.push(newAddedData);
            updatedData = {
              ...updatedData,
              subletting,
            };
          }
        } else if (notice.required === false) {
          notice = [];
          notice.required = false;
          updatedData = {
            ...updatedData,
            notice,
          };
        }
        return updatedData;
      }
      if (action === "remove") {
        let notice = updatedData.notice.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          notice,
        };
        return updatedData;
      }
      return;
    }
    case "Subletting/Assignment Consent Info": {
      if (action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence_id: addedData.sentenceId,
          sentence: sentence,
        };
        let consent = updatedData?.consent?.slice() || [];
        if (!consent) {
          consent = [];
        }
        consent.required = addedData.requirement;
        if (consent.required === true) {
          let isExistsInConsent = false;
          for (let i = 0; i < consent.length; i++) {
            if (
              consent[i].para_id === newAddedData.para_id &&
              consent[i].sentence_id === newAddedData.sentence_id
            ) {
              isExistsInConsent = true;
            }
          }
          if (!isExistsInConsent) {
            consent.push(newAddedData);
            updatedData = {
              ...updatedData,
              consent,
            };
          }
          let subletting = updatedData?.subletting?.slice() || [];
          let isExist = isParaExist(addedData, updatedData?.subletting);

          if (!isExist) {
            subletting.push(newAddedData);
            updatedData = {
              ...updatedData,
              subletting,
            };
          }
        } else if (consent.required === false) {
          consent = [];
          consent.required = false;
          updatedData = {
            ...updatedData,
            consent,
          };
        }
        return updatedData;
      }
      if (action === "remove") {
        let consent = updatedData.consent.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          consent,
        };
        return updatedData;
      }
      return;
    }
    case "Termination clause": {
      if (action === "add") {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        let termination = updatedData?.termination?.slice() || [];
        termination.push(newAddedData);
        updatedData = {
          ...updatedData,
          termination,
        };
        return updatedData;
      }
      if (action === "remove") {
        let termination = updatedData.termination.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        let savedPresent = updatedData.convenience?.present;
        let convenience = updatedData.convenience?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.sentence_id !== addedData.sentence_id
        );

        if (savedPresent) {
          convenience.present = savedPresent;
        }
        if (convenience?.length === 0) {
          convenience.present = "No";
        }

        let lockin = updatedData.lockin?.filter(
          (data: any) =>
            data.para_id !== addedData.para_id ||
            data.sentence_id !== addedData.sentence_id
        );

        let event = updatedData.event?.filter(
          (data: any) =>
            data.eventName !== addedData.eventName ||
            data.phrase !== addedData.phrase
        );
        updatedData = {
          ...updatedData,
          termination,
          convenience,
          lockin,
          event,
        };
        return updatedData;
      }
      return;
    }
    case "Termination at Convenience": {
      if (action === "add") {
        const para = getPara(
          sentenceData,
          addedData.paraId,
          addedData?.rowId,
          addedData?.columnId
        );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
        };
        let termination_at_convenience =
          updatedData?.termination_at_convenience?.slice() || [];
        if (!termination_at_convenience) {
          termination_at_convenience = [];
          termination_at_convenience.present = "No";
        }
        let isExistsInConvenience = false;
        for (let i = 0; i < termination_at_convenience.length; i++) {
          if (termination_at_convenience[i].para_id === newAddedData.para_id) {
            isExistsInConvenience = true;
          }
        }

        if (!isExistsInConvenience) {
          termination_at_convenience.push(newAddedData);
          termination_at_convenience.present = "Yes";
          updatedData = {
            ...updatedData,
            termination_at_convenience,
          };
        }

        const sentence = para;
        const newAddedDataTermination = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          sentence: sentence,
        };
        let termination = parentClauseData?.termination?.slice() || [];

        let isExists = false;
        for (let i = 0; i < termination.length; i++) {
          if (termination[i].para_id === newAddedDataTermination.para_id) {
            isExists = true;
          }
        }
        if (!isExists) {
          termination.push(newAddedDataTermination);
          updatedData = {
            ...updatedData,
            termination,
          };
        }

        return updatedData;
      }
      if (action === "remove") {
        let termination_at_convenience =
          updatedData.termination_at_convenience.filter(
            (data: any) =>
              data.para_id !== addedData.paraId ||
              data.sentence_id !== addedData.sentenceId
          );
        updatedData = {
          ...updatedData,
          termination_at_convenience,
        };
        return updatedData;
      }
      return;
    }
    case "Lock-in Period": {
      if (action === "add") {
        if (addedData !== null) {
          const sentence = getPara(
            sentenceData,
            addedData.paraId,
            addedData?.rowId,
            addedData?.columnId
          );
          let newAddedData = {
            table: [{ row: addedData.rowId, column: addedData.columnId }],
            para_id: addedData.paraId,
            sentence: sentence,
            phrase: addedData.phrase,
          };

          let lockin = [];
          if (addedData !== null) {
            lockin.push(newAddedData);
          }
          updatedData = {
            ...updatedData,
            lockin,
          };
          let termination = updatedData?.termination?.slice() || [];

          let isExists = false;
          for (let i = 0; i < termination.length; i++) {
            if (termination[i].para_id === newAddedData.para_id) {
              isExists = true;
            }
          }
          if (!isExists) {
            termination.push(newAddedData);
            updatedData = {
              ...updatedData,
              termination,
            };
          }
          return updatedData;
        } else {
          updatedData = {
            ...updatedData,
            lockin: [],
          };
          return updatedData;
        }
      }
      if (action === "remove") {
        let lockin = updatedData.lockin.filter(
          (data: any) =>
            data.para_id !== addedData.paraId ||
            data.sentence_id !== addedData.sentenceId
        );
        updatedData = {
          ...updatedData,
          lockin,
        };
        return updatedData;
      }
      return;
    }
    case "Termination Event": {
      if (action === "add") {
        // const sentence = getPara(
        //   sentenceData,
        //   addedData.paraId,
        // );
        let newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          phrase: addedData.phrase,
          eventName: addedData.eventName,
          sentence_id: addedData.sentenceId,
          start_word_id: addedData.startWordId,
          end_word_id: addedData.endWordId,
          eventHighlightId: addedData.eventHighlightId,
          eventId: addedData.eventId,
          id: addedData.id,
        };
        let event = updatedData?.event?.slice() || [];

        let isExistsInEvent = false;
        for (let i = 0; i < event.length; i++) {
          if (
            event[i].para_id === newAddedData.para_id &&
            event[i].phrase === newAddedData.phrase &&
            event[i].eventName === newAddedData.eventName
          ) {
            isExistsInEvent = true;
          }
        }
        if (!isExistsInEvent) {
          event.push(newAddedData);
          updatedData = {
            ...updatedData,
            event,
          };
        }
        let termination = updatedData?.termination?.slice() || [];

        let isExists = false;
        for (let i = 0; i < termination.length; i++) {
          if (termination[i].para_id === newAddedData.para_id) {
            isExists = true;
          }
        }
        if (!isExists) {
          termination.push(newAddedData);
          updatedData = {
            ...updatedData,
            termination,
          };
        }
        return updatedData;
      }
      if (action === "remove") {
        let event = updatedData.event.filter(
          (data: any) =>
            data.eventName !== addedData.eventName ||
            data.phrase !== addedData.phrase
        );
        updatedData = {
          ...updatedData,
          event,
        };
        return updatedData;
      }
      return;
    }
    case "Contracting Parties": {
      if (action === "add") {
        let newAddedData = {
          partyName: addedData.partyName
            ? addedData.partyName
            : addedData.party,
          partyType: addedData.partyType ? addedData.partyType : "",
          id: addedData.id ? addedData.id : "",
        };

        let isExist = false;
        let contracting_parties =
          updatedData?.contracting_parties?.slice() || [];
        for (let i = 0; i < contracting_parties.length; i++) {
          if (contracting_parties[i]?.party === newAddedData.partyName) {
            contracting_parties[i].partyType = newAddedData.partyType;
            isExist = true;
          }
          if (contracting_parties[i]?.partyName === newAddedData.partyName) {
            isExist = true;
          }
        }
        if (!isExist) {
          contracting_parties.push(newAddedData);
        }
        updatedData = {
          ...updatedData,
          contracting_parties,
        };
        return updatedData;
      }
      if (action === "remove") {
        let contracting_parties = updatedData.contracting_parties.filter(
          (data: any) =>
            data.partyName
              ? data.partyName !== addedData.partyName
              : data.party !== addedData.party
        );
        updatedData = {
          ...updatedData,
          contracting_parties,
        };
        return updatedData;
      }
      return;
    }
  }
};

export const deleteClauseData = (
  fileID: string,
  payload: any,
  clauseType: string,
  wholeData: any,
  postClauseDataByType: (
    fileId: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void
) => {
  let editedPayload = {};
  let updatedPayload = {};
  let updatedObject = {};
  switch (clauseType) {
    case "Term clause":
      if (wholeData?.edited_content?.term) {
        wholeData.edited_content.term.forEach((item: any, index: number) => {
          payload.some((element: any) => {
            const { deleted, ...rest } = element;
            if (JSON.stringify(rest) === JSON.stringify(item)) {
              wholeData.edited_content.term[index] = { ...element };
              updatedPayload = {
                term: [...wholeData.edited_content.term],
              };
            } else {
              updatedPayload = {
                term: [...wholeData.edited_content.term, ...payload],
              };
            }
            return;
          });
        });
      } else {
        updatedPayload = {
          term: [...payload],
        };
      }
      editedPayload = {
        edited_content: updatedPayload,
      };

      updatedObject = {
        edited_content: { ...wholeData.edited_content, updatedPayload },
        raw_content: wholeData.raw_content,
      };

      return postClauseDataByType(fileID, "term", editedPayload, updatedObject);
  }
};

export const CLAUSE_DATA = {
  term_clause: {
    heading: "Term Clause",
    start_date: "Start Dates",
    end_date: "End Dates",
    duration: "Duration",
  },
  renewal_clause: {
    heading: "Renewal Clause",
    auto_renewal: "Auto Renewal",
    notice_auto_renewal: "Notice for Auto-Renewal",
    notice_reference_date: "Notice Reference Date",
    duration: "Duration",
  },
  indemnity_clause: {
    heading: "Indemnity/Reimbursements/Costs",
    extent_cost: "Extent of costs",
    triggering_events: "Triggering Events",
  },
  confidentiality_clause: {
    heading: "Confidentiality Clause",
    post_contract: "Post Contract Duration",
    nature: "Nature",
    exception_confidentiality: "Exception to Confidentiality",
  },
};
