import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@material-ui/core";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";

export default function OtherObligationClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    hasData,
    updatedClauseData,
    postClauseDataByType,
    otherClauses,
  } = props;

  const [counter, setCounter] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const [updateObligation, setUpdateObligation] = React.useState(true);

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "obligation_statements");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.obligationStatements && clauseData.obligationStatements,
    [clauseData?.obligationStatements]
  );

  const isClauseProperty = useCallback(
    (clauseType: string) => {
      return clauseData?.hasOwnProperty(clauseType);
    },
    [clauseData]
  );

  const updates = useMemo(
    () => updatedClauseData?.obligationStatements,
    [updatedClauseData]
  );

  const obligationStatements = useMemo(() => {
    if (updates?.obligation_statements) {
      let sortData = updates.obligation_statements.sort(function (
        a: any,
        b: any
      ) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const getClauseData = React.useCallback(
    (clauseType: string) => {
      switch (clauseType) {
        case "contractingParties": {
          return (
            updatedClauseData?.contractingParties?.contracting_parties || []
          );
        }
        case "nonCompete": {
          return updatedClauseData?.nonCompete?.non_compete || [];
        }
        case "changeOfControl": {
          return updatedClauseData?.changeOfControl?.change_of_control || [];
        }
        case "confidentiality": {
          return updatedClauseData?.confidentiality?.confidentiality || [];
        }
        case "consent": {
          return updatedClauseData?.consent?.consent || [];
        }
        case "dataBreach": {
          return updatedClauseData?.dataBreach?.data_breach || [];
        }
        case "disputeResolution": {
          return updatedClauseData?.disputeResolution?.dispute_resolution || [];
        }
        case "effectiveDate": {
          return updatedClauseData?.effectiveDate?.effective_date || [];
        }
        case "eventsOfDefault": {
          return updatedClauseData?.eventsOfDefault?.events_of_default || [];
        }
        case "exclusivity": {
          return updatedClauseData?.exclusivity?.exclusivity || [];
        }
        case "forceMajeure": {
          return updatedClauseData?.forceMajeure?.force_majeure || [];
        }
        case "generalDefinitions": {
          return (
            updatedClauseData?.generalDefinitions?.general_definitions || []
          );
        }
        case "governingLaw": {
          return updatedClauseData?.governingLaw?.governing_law || [];
        }
        case "indemnity": {
          return updatedClauseData?.indemnity?.indemnity || [];
        }
        case "insurance": {
          return updatedClauseData?.insurance?.insurance || [];
        }
        case "limitedLiability": {
          return updatedClauseData?.limitedLiability?.limited_liability || [];
        }
        case "term": {
          return updatedClauseData?.term?.term || [];
        }
        case "renewal": {
          return updatedClauseData?.renewal?.renewal || [];
        }
        case "payment": {
          return updatedClauseData?.payment?.payment || [];
        }
        case "termination": {
          return updatedClauseData?.termination?.termination || [];
        }
        case "notice": {
          return updatedClauseData?.notice?.notice || [];
        }
        case "nonSolicitation": {
          return updatedClauseData?.nonSolicitation?.non_solicitation || [];
        }
        case "subletting": {
          return updatedClauseData?.subletting?.subletting || [];
        }
      }
    },
    [updatedClauseData]
  );

  const getOtherClausesData = React.useCallback(() => {
    let clauseData: any[] = [];

    if (isClauseProperty("contractingParties")) {
      let data = getClauseData("contractingParties");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("nonCompete")) {
      let data = getClauseData("nonCompete");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("changeOfControl")) {
      let data = getClauseData("changeOfControl");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("confidentiality")) {
      let data = getClauseData("confidentiality");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("consent")) {
      let data = getClauseData("consent");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("dataBreach")) {
      let data = getClauseData("dataBreach");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("disputeResolution")) {
      let data = getClauseData("disputeResolution");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("effectiveDate")) {
      let data = getClauseData("effectiveDate");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("eventsOfDefault")) {
      let data = getClauseData("eventsOfDefault");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("exclusivity")) {
      let data = getClauseData("exclusivity");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("forceMejeure")) {
      let data = getClauseData("forceMejeure");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("generalDefinitions")) {
      let data = getClauseData("generalDefinitions");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("governingLaw")) {
      let data = getClauseData("governingLaw");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("indemnity")) {
      let data = getClauseData("indemnity");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("insurance")) {
      let data = getClauseData("insurance");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("limitedLiability")) {
      let data = getClauseData("limitedLiability");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("term")) {
      let data = getClauseData("term");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("renewal")) {
      let data = getClauseData("renewal");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("payment")) {
      let data = getClauseData("payment");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("termination")) {
      let data = getClauseData("termination");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("notice")) {
      let data = getClauseData("notice");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("nonSolicitation")) {
      let data = getClauseData("nonSolicitation");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (isClauseProperty("subletting")) {
      let data = getClauseData("subletting");
      if (data?.length > 0) {
        clauseData = [...clauseData, ...data];
      }
    }
    return clauseData;
  }, [getClauseData, isClauseProperty]);

  React.useEffect(() => {
    if (
      !data?.edited_content &&
      obligationStatements.length > 0 &&
      updateObligation
    ) {
      let otherClausesData = getOtherClausesData();
      let obligation_statements = obligationStatements.slice();
      let updatedData = updates;
      otherClausesData.filter((data: any) => {
        obligation_statements = obligation_statements.filter(
          (obligation: any) =>
            obligation.para_id !== data.para_id ||
            obligation.sentence_id !== data.sentence_id
        );
      });

      setUpdateObligation(false);
      updatedData = {
        ...updates,
        obligation_statements,
      };

      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(
          fileId,
          "obligation_statements",
          diff,
          updatedData
        );
      }
    }
  }, [obligationStatements, updateObligation]);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(obligationStatements, "obligation_statements");
    setCounter(0);
  };

  const deleteClause = React.useCallback(
    (item: any) => {
      let obligation_statements = obligationStatements?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let updatedData = {
        ...updates,
        obligation_statements,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(
          fileId,
          "obligation_statements",
          diff,
          updatedData
        );
      }
    },
    [updates, postClauseDataByType, obligationStatements, data, fileId]
  );

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Other Obligation Clause</p>

            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", obligationStatements);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${obligationStatements?.length}`
                  : obligationStatements?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (obligationStatements?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", obligationStatements);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {obligationStatements.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleAddEdit(
                          props,
                          "obligation_statements",
                          obligationStatements,
                          setShowAdd
                        );
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited="obligation_statements"
                savedInsight={
                  !isNullOrUndefined(obligationStatements)
                    ? obligationStatements
                    : []
                }
                savedParentClauseDataPoint={obligationStatements}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                otherClauses={(type: string) => otherClauses(type)}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
              />
            )}

            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {obligationStatements?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(
                        index,
                        item,
                        "obligation_statements"
                      )
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      {item.sentence
                        ? item.sentence
                        : getPara(
                            props?.sentenceData,
                            item.para_id,
                            item.sentence_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteClause(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
