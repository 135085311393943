import { SITE_API_BY_REALM_NAME } from "../../../../Configuration/global";
import { call, put, takeLatest, all, take } from "redux-saga/effects";
import { GetClauseErrorData, GETCLAUSEERRORDATA } from "../Action/def";
import ClausErrorManagementAPI from "../Action/API";
import ClauseErrorData, {
  ClauseErrorList,
} from "../State/clauseErrorManagementState";
import ClauseErrorManagementActionGenerator from "../Action/gen";
import { getKeyCloakRealmFromLS } from "../../../../Authentication/Actions/authentication";
import NotificationGenerator from "../../../Notification/Actions/gen";
const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());

function* getClauseErrorData(action: GetClauseErrorData) {
  let id = action.payload.fileId;

  let contract_id = atob(id);
  let url = SITEAPI + "kdp-clause-error/" + contract_id + "/";

  // try {
  //   const response = yield call(
  //     ClausErrorManagementAPI.getClauseErrorData,
  //     url
  //   );

  //   switch (response.status) {
  //     case 200: {
  //       const parsed = response.data.queryResult as ClauseErrorList[];
  //       yield put(
  //         ClauseErrorManagementActionGenerator.getClauseErrorDataSuccess(parsed)
  //       );
  //       break;
  //     }
  //     default: {
  //       yield put(
  //         ClauseErrorManagementActionGenerator.getClauseErrorDataFailure()
  //       );
  //     }
  //   }
  // } catch (e) {
  //   yield put(ClauseErrorManagementActionGenerator.getClauseErrorDataFailure());
  // }
}

export default function* ClausErrorManagementWatcher() {
  yield all([takeLatest(GETCLAUSEERRORDATA, getClauseErrorData)]);
}
