import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@material-ui/core";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { AmountEdit } from "./AmountEdit";
import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import { DurationEdit } from "./DurationEdit";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { LinkPhraseRequest } from "../../State/documentState";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import { truncateString } from "../../../Utils/DataModifierUtil/dataModUtil";

export default function PaymentObligationsClause({
  getClauseDataByType,
  fileId,
  clauseData,
  updatedClauseData,
  postClauseDataByType,
  durationList,
  currencyList,
  sentenceData,
  hasData,
  ...props
}: ClauseComponentInterface) {
  const [counter, setCounter] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const [editAmount, setEditAmount] = useState(false);
  const [amountId, setAmountId] = useState(-1);
  const [editTimeline, setEditTimeline] = useState(false);
  const [timelineId, setTimelineId] = useState(-1);
  const [showAddAmount, setShowAddAmount] = useState(false);
  const [showAddTimeline, setShowAddTimeline] = useState(false);
  const [isChildDeleted, setChildDeleted] = React.useState(true);

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "payment");
  }, [fileId, hasData]);

  const data = useMemo(
    () => clauseData?.payment && clauseData.payment,
    [clauseData?.payment]
  );

  const updates = useMemo(
    () => updatedClauseData?.payment || {},
    [updatedClauseData]
  );

  const payment = useMemo(() => {
    if (updates?.payment) {
      let sortData = updates.payment.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const paymentAmounts = useMemo(() => {
    if (updates?.amounts) {
      let sortData = updates.amounts.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const paymentTimelines = useMemo(() => {
    if (updates?.timeline) {
      let sortData = updates.timeline.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      sortData.map((data: any) => {
        const index = durationList.findIndex(
          (list) => list.durationTypeId === data.duration_type_id
        );
        const typeIndex = durationList.findIndex(
          (list) => list.durationName === data.duartion_type
        );
        if (index > -1) {
          const type = durationList[index].durationName;
          data.duration_type = type;
        }
        if (!data.duration_type_id && typeIndex > -1) {
          const id = durationList[typeIndex].durationTypeId;
          data.duration_type_id = id;
        }
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(payment, "Payment Obligation");
    setCounter(0);
  };

  React.useEffect(() => {
    if (data && data?.edited_content === null && isChildDeleted) {
      let updatedData = {
        ...updates,
        amounts: [],
        timeline: [],
      };
      if (payment && payment.length) {
        paymentAmounts?.map((paymentAmount: any) => {
          let index = payment.findIndex(
            (item: any) =>
              item.para_id !== paymentAmount.para_id ||
              item.sentence_id !== paymentAmount.sentence_id
          );
          if (index > -1) {
            paymentAmounts?.splice(index, 1);
          }
        });
        paymentTimelines?.map((paymentTimeline: any) => {
          let index = payment.findIndex(
            (item: any) =>
              item.para_id !== paymentTimeline.para_id ||
              item.sentence_id !== paymentTimeline.sentence_id
          );
          if (index > -1) {
            paymentTimelines?.splice(index, 1);
          }
        });
        setChildDeleted(false);
        updatedData = {
          ...updates,
          amounts: paymentAmounts,
          timeline: paymentTimelines,
        };
      }
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "payment", diff, updatedData);
        getClauseDataByType(fileId, "payment");
      }
    }
  }, [
    data,
    updates,
    payment,
    paymentAmounts,
    paymentTimelines,
    isChildDeleted,
  ]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let _payment = payment?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );

      let amounts = paymentAmounts?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );
      let timeline = paymentTimelines?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );

      let updatedData = {
        ...updates,
        payment: _payment,
        amounts,
        timeline,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "payment", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, payment, data, fileId]
  );

  const deleteAmount = React.useCallback(
    (item: any) => {
      let amounts = paymentAmounts?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id ||
          data.end_sentence_id !== item.end_sentence_id ||
          data.start_word_id !== item.start_word_id ||
          data.end_word_id !== item.end_word_id
      );
      let updatedData = {
        ...updates,
        amounts,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "payment", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, paymentAmounts, data, fileId]
  );

  const deleteTimeline = React.useCallback(
    (item: any) => {
      let timeline = paymentTimelines?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id ||
          data.end_sentence_id !== item.end_sentence_id ||
          data.start_word_id !== item.start_word_id ||
          data.end_word_id !== item.end_word_id
      );
      let updatedData = {
        ...updates,
        timeline,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "payment", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, paymentAmounts, data, fileId]
  );

  const editAmountPhrase = useCallback(
    (data: any, index: number) => {
      let amounts = paymentAmounts.slice();
      amounts[index] = data;
      let updatedData = {
        ...updates,
        amounts,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "payment", diff, updatedData);
      }
    },
    [paymentAmounts]
  );

  const editTimelinePhrase = React.useCallback(
    (data: any, index: number) => {
      let duration = paymentTimelines.slice();
      duration[index] = data;
      let updatedData = {
        ...updates,
        duration,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "payment", diff, updatedData);
      }
    },
    [paymentTimelines]
  );

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Payment Obligation</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", payment);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand ? `${counter} / ${payment?.length}` : payment?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (payment?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", payment);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {payment.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleAddEdit(props, "payment", payment, setShowAdd);
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={fileId}
                toBeEdited="payment"
                savedInsight={!isNullOrUndefined(payment) ? payment : []}
                savedParentClauseDataPoint={payment}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
                parentClauseType="payment"
              />
            )}
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {payment?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(index, item, "payment")
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.sentence
                        ? item.sentence
                        : getPara(
                            sentenceData,
                            item.para_id,
                            item.start_sentence_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-payment"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteClause(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Amount</p>
              {!showAddAmount ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Amount",
                      paymentAmounts,
                      setShowAddAmount
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={fileId}
                  toBeEdited="Payment Amounts"
                  savedInsight={
                    !isNullOrUndefined(paymentAmounts) ? paymentAmounts : []
                  }
                  savedParentClauseDataPoint={paymentAmounts}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddAmount(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="payment"
                />
              )}
            </div>
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {paymentAmounts?.map((capItem: any, index: number) => (
                  <div key={index}>
                    <div>
                      {editAmount && amountId === index ? (
                        <AmountEdit
                          dataPointName="Payment Amounts"
                          currencyList={currencyList}
                          savedInsight={
                            !isNullOrUndefined(capItem) ? capItem : []
                          }
                          editPhrasesRequest={(
                            newPhraseRequest: LinkPhraseRequest
                          ) => {
                            editAmountPhrase(newPhraseRequest, index);
                          }}
                          clearNormalizedEdit={() => setEditAmount(false)}
                          selectedAmount={capItem}
                        />
                      ) : (
                        <div
                          className={
                            editAmount && amountId === index
                              ? " mr-1 toc-content toc-content-active animate__animated animate__fadeInDown"
                              : " mr-1 toc-content toc-content-default animate__animated animate__fadeInDown"
                          }
                          style={{
                            border:
                              editAmount && amountId === index
                                ? "2px solid #882f5e"
                                : "",
                            marginBottom: "1px",
                          }}
                        >
                          <div
                            className="bi-clause-clickable"
                            onClick={() =>
                              props.onClickHighlight(
                                index,
                                capItem,
                                "Payment Amounts"
                              )
                            }
                          >
                            <div className="d-flex flex-row px-3">
                              <span style={{ color: "#4D4D4D" }}>
                                {index + 1}. &nbsp;&nbsp;
                              </span>
                              <span>
                                {capItem.total < 0 ? (
                                  <img
                                    src="/static_images/empty-dash-grey-icn.svg"
                                    alt="empty"
                                  />
                                ) : capItem.total > -1 &&
                                  (capItem.total + " " + capItem.currency)
                                    .length > 30 ? (
                                  <DarkTooltip
                                    title={
                                      capItem.total + " " + capItem.currency
                                    }
                                    placement={"right-end"}
                                  >
                                    <>
                                      {truncateString(
                                        capItem.total + " " + capItem.currency,
                                        30
                                      )}
                                    </>
                                  </DarkTooltip>
                                ) : (
                                  `${capItem.total || "_"}  ${
                                    capItem.currency || "_"
                                  }`
                                )}
                                <DarkTooltip title="Edit" placement="right-end">
                                  <img
                                    src="/static_images/new-edit-icon.svg"
                                    alt="edit"
                                    onClick={() => {
                                      setEditAmount(true);
                                      setAmountId(index);
                                    }}
                                  />
                                </DarkTooltip>
                              </span>
                              <img
                                className="cursor-pointer ml-auto "
                                style={{ zIndex: 2 }}
                                src="/static_images/delete-insight-icn.svg"
                                alt="delete-currency-insight"
                                data-toggle="modal"
                                data-target="#deleteInsightModal"
                                onClick={() => deleteAmount(capItem)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        editAmount && amountId === index
                          ? "mr-1 toc-content toc-content-active animate__animated animate__fadeInDown p-2 mb-1"
                          : "mr-1 toc-content toc-content-default animate__animated animate__fadeInDown p-2 mb-1 cursor-pointer"
                      }
                      style={{
                        border:
                          editAmount && amountId === index
                            ? "2px solid #882f5e"
                            : "",
                      }}
                      key={index}
                      onClick={() =>
                        props.onClickHighlight(
                          index,
                          capItem,
                          "Payment Amounts"
                        )
                      }
                    >
                      {capItem.amountString
                        ? capItem.amountString
                        : getPara(
                            sentenceData,
                            capItem.para_id,
                            capItem.table && capItem.table[0]?.row,
                            capItem.table && capItem.table[0]?.column
                          )}
                    </div>
                  </div>
                ))}
              </Scrollable>
            </div>
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Timeline</p>
              {!showAddTimeline ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Payment Timeline",
                      paymentTimelines,
                      setShowAddTimeline
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={fileId}
                  toBeEdited="Payment Timeline"
                  savedInsight={
                    !isNullOrUndefined(paymentTimelines) ? paymentTimelines : []
                  }
                  savedParentClauseDataPoint={paymentTimelines}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddTimeline(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="payment"
                />
              )}
            </div>
            {paymentTimelines &&
              paymentTimelines.map((durationData: any, index: number) => (
                <div key={index}>
                  {editTimeline && timelineId === index ? (
                    <DurationEdit
                      key={index}
                      durationList={durationList}
                      savedInsight={
                        !isNullOrUndefined(paymentTimelines)
                          ? paymentTimelines
                          : []
                      }
                      editPhrasesRequest={(
                        newPhraseRequest: LinkPhraseRequest
                      ) => {
                        editTimelinePhrase(newPhraseRequest, index);
                      }}
                      clearNormalizedEdit={() => setEditTimeline(false)}
                      selectedDuration={durationData}
                    />
                  ) : (
                    <div
                      className={
                        editTimeline && timelineId === index
                          ? " mr-1 toc-content toc-content-active animate__animated animate__fadeInDown"
                          : " mr-1 toc-content toc-content-default animate__animated animate__fadeInDown"
                      }
                      style={{
                        border:
                          editTimeline && timelineId === index
                            ? "2px solid #882f5e"
                            : "",
                        marginBottom: "1px",
                      }}
                    >
                      <div
                        className="bi-clause-clickable"
                        onClick={() =>
                          props.onClickHighlight(
                            index,
                            durationData,
                            "Payment Timeline"
                          )
                        }
                      >
                        <div className="d-flex flex-row px-3">
                          <span style={{ color: "#4D4D4D" }}>
                            {index + 1}. &nbsp;&nbsp;
                          </span>
                          <span>
                            {durationData?.duration_value < 0 ? (
                              <img
                                src="/static_images/empty-dash-grey-icn.svg"
                                alt="empty"
                              />
                            ) : durationData?.duration_value > -1 &&
                              (
                                durationData?.duration_value +
                                " " +
                                durationData.duration_type
                              ).length > 30 ? (
                              <DarkTooltip
                                title={
                                  durationData?.duration_value +
                                  " " +
                                  durationData?.duration_type
                                }
                                placement={"right-end"}
                              >
                                <>
                                  {truncateString(
                                    durationData?.duration_value +
                                      " " +
                                      durationData.duration_type,
                                    30
                                  )}
                                </>
                              </DarkTooltip>
                            ) : (
                              `${durationData.duration_value} ${durationData.duration_type}`
                            )}
                            <DarkTooltip title={"Edit"} placement="right-end">
                              <img
                                src="/static_images/new-edit-icon.svg"
                                alt="edit"
                                onClick={() => {
                                  setEditTimeline(true);
                                  setTimelineId(index);
                                }}
                                className="pl-2"
                              />
                            </DarkTooltip>
                          </span>
                          <img
                            className="cursor-pointer ml-auto "
                            style={{ zIndex: 2 }}
                            src="/static_images/delete-insight-icn.svg"
                            alt="delete-duration-insight"
                            data-toggle="modal"
                            data-target="#deleteInsightModal"
                            onClick={() => deleteTimeline(durationData)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      editTimeline && timelineId === index
                        ? "mr-1 toc-content toc-content-active animate__animated animate__fadeInDown p-2 mb-1"
                        : "mr-1 toc-content toc-content-default animate__animated animate__fadeInDown p-2 mb-1"
                    }
                    style={{
                      border:
                        editTimeline && timelineId === index
                          ? "2px solid #882f5e"
                          : "",
                    }}
                  >
                    {durationData.phrase}
                  </div>
                </div>
              ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
