import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@material-ui/core";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useEffect, useMemo, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";

export default function EventClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [counter, setCounter] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const [showAddEvents, setShowAddEvents] = useState(false);

  useEffect(() => {
    hasData && getClauseDataByType(props.fileId, "events_of_default");
  }, [hasData, fileId]);

  const data = React.useMemo(
    () => clauseData?.eventsOfDefault && clauseData.eventsOfDefault,
    [clauseData?.eventsOfDefault]
  );

  const updates = React.useMemo(
    () => updatedClauseData?.eventsOfDefault || {},
    [updatedClauseData]
  );

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(
      data?.raw_content?.events_of_default,
      "Events of Default"
    );
    setCounter(0);
  };

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  const eventsOfDefault = useMemo(() => {
    if (updates?.events_of_default) {
      let sortData = updates.events_of_default.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const events = useMemo(() => {
    if (updates?.events) {
      let sortData = updates.events.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let events_of_default = eventsOfDefault?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let eventsData = events?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let updatedData = {
        ...updates,
        events_of_default,
        events: eventsData,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "events_of_default", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, eventsOfDefault, data, fileId]
  );

  const deleteEvents = React.useCallback(
    (item: any) => {
      let newEvents = events?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        events: newEvents,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "events_of_default", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, events, data, fileId]
  );

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Events of Default</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", eventsOfDefault);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${eventsOfDefault?.length}`
                  : eventsOfDefault?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (eventsOfDefault?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", eventsOfDefault);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {eventsOfDefault.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        props.onEdit(
                          "Events of Default",
                          0,
                          false,
                          [],
                          -1,
                          "",
                          eventsOfDefault
                        );
                        setShowAdd(true);
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited="events_of_default"
                savedInsight={
                  !isNullOrUndefined(eventsOfDefault) ? eventsOfDefault : []
                }
                savedParentClauseDataPoint={eventsOfDefault}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
              />
            )}
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {eventsOfDefault?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor_pointer"
                    onClick={() =>
                      props.onClickHighlight(index, item, "events_of_default")
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.para
                        ? item.para
                        : getPara(
                            props?.sentenceData,
                            item.para_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteClause(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
            <div className="d-flex flex-column mt-2">
              <div className="clause-sub-heading">
                <p className="m-0">Event</p>
                {!showAddEvents ? (
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      handleAddEdit(
                        props,
                        "Events Of Default Event",
                        events,
                        setShowAddEvents
                      );
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                ) : (
                  <EditFeature
                    fileId={props.fileId}
                    toBeEdited="Events Of Default Event"
                    savedInsight={!isNullOrUndefined(events) ? events : []}
                    savedParentClauseDataPoint={events}
                    editOptionSelected={(selected: boolean) => {
                      props.editOptionSelected(selected);
                    }}
                    childInEditId={props.childInEditId}
                    onClose={() => {
                      setShowAddEvents(false);
                    }}
                    clauseDataByType={data}
                    updatedClauseDataByType={updates}
                    parentClauseType="events_of_default"
                  />
                )}
              </div>
              <div className="mt-2">
                <Scrollable maxHeight={200}>
                  {events?.map((item: any, index: number) => (
                    <div className="clause-div" key={index}>
                      <p className="clause-data">
                        {item.events ||
                          getPara(
                            props?.sentenceData,
                            item.para_id,
                            item.sentence_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                      </p>
                      <img
                        className="cursor-pointer"
                        style={{ zIndex: 2 }}
                        src="/static_images/delete-insight-icn.svg"
                        alt="delete-insight"
                        data-toggle="modal"
                        data-target="#deleteInsightModal"
                        onClick={() => deleteEvents(item)}
                      />
                    </div>
                  ))}
                </Scrollable>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
