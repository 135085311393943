import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@material-ui/core";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useEffect, useMemo, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";

export default function DisputeClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    hasData,
    updatedClauseData,
    postClauseDataByType,
  } = props;

  const [counter, setCounter] = useState(1);
  const [isExpand, setExpand] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddArbitration, setShowAddArbitration] = useState(false);
  const [showAddConciliation, setShowAddConciliation] = useState(false);
  const [showAddMediation, setShowAddMediation] = useState(false);
  const [showAddNegotiation, setShowAddNegotiation] = useState(false);
  const [showAddOthers, setShowAddOthers] = useState(false);
  const [showAddPanel, setShowAddPanel] = useState(false);
  const [showAddAct, setShowAddAct] = useState(false);
  const [showAddVenue, setShowAddVenue] = useState(false);

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "dispute_resolution");
  }, [fileId, hasData]);

  const data = useMemo(
    () => clauseData?.disputeResolution || null,
    [clauseData?.disputeResolution]
  );

  const updates = useMemo(
    () => updatedClauseData?.disputeResolution || {},
    [updatedClauseData]
  );

  const disputeResolution = useMemo(() => {
    if (updates?.dispute_resolution) {
      let sortData = updates.dispute_resolution.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const arbitration = useMemo(() => {
    if (disputeResolution) {
      let data = disputeResolution.filter((filterData: any) => {
        let found = filterData.modes?.some(
          (item: any) => item.key === "Arbitration"
        );
        if (found) {
          return filterData;
        }
      });
      return data;
    }
  }, [disputeResolution]);

  const conciliation = useMemo(() => {
    if (disputeResolution) {
      let data = disputeResolution.filter((filterData: any) => {
        let found = filterData.modes?.some(
          (item: any) => item.key === "Conciliation"
        );
        if (found) {
          return filterData;
        }
      });
      return data;
    }
  }, [disputeResolution]);

  const mediation = useMemo(() => {
    if (disputeResolution) {
      let data = disputeResolution.filter((filterData: any) => {
        let found = filterData.modes?.some(
          (item: any) => item.key === "Mediation"
        );
        if (found) {
          return filterData;
        }
      });
      return data;
    }
  }, [disputeResolution]);

  const negotiation = useMemo(() => {
    if (disputeResolution) {
      let data = disputeResolution.filter((filterData: any) => {
        let found = filterData.modes?.some(
          (item: any) => item.key === "Negotiation"
        );
        if (found) {
          return filterData;
        }
      });
      return data;
    }
  }, [disputeResolution]);

  const others = useMemo(() => {
    if (disputeResolution) {
      let data = disputeResolution.filter((filterData: any) => {
        let found = filterData.modes?.some(
          (item: any) => item.key === "Others"
        );
        if (found) {
          return filterData;
        }
      });
      return data;
    }
  }, [disputeResolution]);

  // it's an Act/Statue in UI
  const legalEntities = useMemo(() => {
    if (updates?.legal_entities) {
      let sortData = updates.legal_entities.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const venue = useMemo(() => {
    if (updates?.venue) {
      let sortData = updates.venue.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const panel = useMemo(() => {
    if (updates?.panel) {
      let sortData = updates.panel.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(disputeResolution, "dispute_resolution");
    setCounter(0);
  };

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let dispute_resolution = disputeResolution?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let venue_data = venue?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let panel_data = panel?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let legal_entities = legalEntities?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let updatedData = {
        ...updates,
        dispute_resolution,
        venue: venue_data,
        panel: panel_data,
        legal_entities,
      };

      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "dispute_resolution", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, disputeResolution, data, fileId]
  );

  const deleteVenue = React.useCallback(
    (item: any) => {
      let venue_data = venue?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );
      let updatedData = {
        ...updates,
        venue: venue_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "dispute_resolution", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, venue, data, fileId]
  );

  const deletePanel = React.useCallback(
    (item: any) => {
      let panel_data = panel?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );
      let updatedData = {
        ...updates,
        panel: panel_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "dispute_resolution", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, venue, data, fileId]
  );

  const deleteLegalEntity = React.useCallback(
    (item: any) => {
      let legal_entities = legalEntities?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );
      let updatedData = {
        ...updates,
        legal_entities: legal_entities,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "dispute_resolution", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, venue, data, fileId]
  );

  const deleteClauseModes = React.useCallback(
    (item: any, mode: string) => {
      const dispute_resolution = disputeResolution.filter((data: any) => {
        if (data.para_id === item.para_id) {
          let modes = data.modes.filter((item: any) => item.key !== mode);
          data.modes = modes;
        }
        return data;
      });
      let updatedData = {
        ...updates,
        dispute_resolution,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "dispute_resolution", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, data, fileId]
  );

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && "toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Dispute Resolution</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();

                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", disputeResolution);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${disputeResolution?.length}`
                  : disputeResolution?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    props.onScroll("down", disputeResolution);
                    e.stopPropagation();

                    if (disputeResolution?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("up", disputeResolution);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {disputeResolution.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleAddEdit(
                          props,
                          "dispute_resolution",
                          disputeResolution,
                          setShowAdd
                        );
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited="dispute_resolution"
                savedInsight={
                  !isNullOrUndefined(disputeResolution) ? disputeResolution : []
                }
                savedParentClauseDataPoint={disputeResolution}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
                parentClauseType="dispute_resolution"
              />
            )}
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {disputeResolution?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(index, item, "dispute_resolution")
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.para
                        ? item.para
                        : getPara(
                            props?.sentenceData,
                            item.para_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-dispute-resolution"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteClause(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
            {/* Arbitration block start */}
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Arbitration</p>
              {!showAddArbitration ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Dispute Resolution Arbitration",
                      arbitration,
                      setShowAddArbitration
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Dispute Resolution Arbitration"
                  savedInsight={
                    !isNullOrUndefined(arbitration) ? arbitration : []
                  }
                  savedParentClauseDataPoint={arbitration}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddArbitration(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="dispute_resolution"
                />
              )}
            </div>
            <div className="mt-2">
              {arbitration?.map((item: any, index: number) => (
                <div
                  className="clause-div cursor-pointer"
                  onClick={() =>
                    props.onClickHighlight(index, item, "arbitration")
                  }
                  key={index}
                >
                  <p className="clause-data">
                    <span>{index + 1}.&nbsp;</span>
                    {item.para
                      ? item.para
                      : getPara(
                          props?.sentenceData,
                          item.para_id,
                          item.table && item.table[0]?.row,
                          item.table && item.table[0]?.column
                        )}
                  </p>
                  <img
                    className="cursor-pointer"
                    style={{ zIndex: 2 }}
                    src="/static_images/delete-insight-icn.svg"
                    alt="delete-insight"
                    data-toggle="modal"
                    data-target="#deleteInsightModal"
                    onClick={() => deleteClauseModes(item, "Arbitration")}
                  />
                </div>
              ))}
            </div>
            {/* Arbitration block end */}
            {/* Conciliation block start */}
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Conciliation</p>
              {!showAddConciliation ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Dispute Resolution Conciliation",
                      conciliation,
                      setShowAddConciliation
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Dispute Resolution Conciliation"
                  savedInsight={
                    !isNullOrUndefined(conciliation) ? conciliation : []
                  }
                  savedParentClauseDataPoint={conciliation}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddConciliation(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="dispute_resolution"
                />
              )}
            </div>
            <div className="mt-2">
              {conciliation?.map((item: any, index: number) => (
                <div
                  className="clause-div cursor-pointer"
                  onClick={() =>
                    props.onClickHighlight(index, item, "conciliation")
                  }
                  key={index}
                >
                  <p className="clause-data">
                    <span>{index + 1}.&nbsp;</span>
                    {item.para
                      ? item.para
                      : getPara(
                          props?.sentenceData,
                          item.para_id,
                          item.table && item.table[0]?.row,
                          item.table && item.table[0]?.column
                        )}
                  </p>
                  <img
                    className="cursor-pointer"
                    style={{ zIndex: 2 }}
                    src="/static_images/delete-insight-icn.svg"
                    alt="delete-insight"
                    data-toggle="modal"
                    data-target="#deleteInsightModal"
                    onClick={() => deleteClauseModes(item, "Conciliation")}
                  />
                </div>
              ))}
            </div>
            {/* Conciliation block end */}

            {/* Mediation block start */}
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Mediation</p>
              {!showAddMediation ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Dispute Resolution Mediation",
                      mediation,
                      setShowAddMediation
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Dispute Resolution Mediation"
                  savedInsight={!isNullOrUndefined(mediation) ? mediation : []}
                  savedParentClauseDataPoint={mediation}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddMediation(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="dispute_resolution"
                />
              )}
            </div>
            <div className="mt-2">
              {mediation?.map((item: any, index: number) => (
                <div
                  className="clause-div cursor-pointer"
                  onClick={() =>
                    props.onClickHighlight(index, item, "mediation")
                  }
                  key={index}
                >
                  <p className="clause-data">
                    <span>{index + 1}.&nbsp;</span>
                    {item.para
                      ? item.para
                      : getPara(
                          props?.sentenceData,
                          item.para_id,
                          item.table && item.table[0]?.row,
                          item.table && item.table[0]?.column
                        )}
                  </p>
                  <img
                    className="cursor-pointer"
                    style={{ zIndex: 2 }}
                    src="/static_images/delete-insight-icn.svg"
                    alt="delete-insight"
                    data-toggle="modal"
                    data-target="#deleteInsightModal"
                    onClick={() => deleteClauseModes(item, "Mediation")}
                  />
                </div>
              ))}
            </div>
            {/* Mediation block end */}
            {/* Negotiation block start */}
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Negotiation</p>
              {!showAddNegotiation ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Dispute Resolution Negotiation",
                      negotiation,
                      setShowAddNegotiation
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Dispute Resolution Negotiation"
                  savedInsight={
                    !isNullOrUndefined(negotiation) ? negotiation : []
                  }
                  savedParentClauseDataPoint={negotiation}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddNegotiation(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="dispute_resolution"
                />
              )}
            </div>
            <div className="mt-2">
              {negotiation?.map((item: any, index: number) => (
                <div
                  className="clause-div cursor-pointer"
                  onClick={() =>
                    props.onClickHighlight(index, item, "negoriation")
                  }
                  key={index}
                >
                  <p className="clause-data">
                    <span>{index + 1}.&nbsp;</span>
                    {item.para
                      ? item.para
                      : getPara(
                          props?.sentenceData,
                          item.para_id,
                          item.table && item.table[0]?.row,
                          item.table && item.table[0]?.column
                        )}
                  </p>
                  <img
                    className="cursor-pointer"
                    style={{ zIndex: 2 }}
                    src="/static_images/delete-insight-icn.svg"
                    alt="delete-insight"
                    data-toggle="modal"
                    data-target="#deleteInsightModal"
                    onClick={() => deleteClauseModes(item, "Negotiation")}
                  />
                </div>
              ))}
            </div>
            {/* Negotiation block end */}

            {/* Others block start */}
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Others</p>
              {!showAddOthers ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Dispute Resolution Others",
                      others,
                      setShowAddOthers
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Dispute Resolution Others"
                  savedInsight={!isNullOrUndefined(others) ? others : []}
                  savedParentClauseDataPoint={others}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddOthers(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="dispute_resolution"
                />
              )}
            </div>
            <div className="mt-2">
              {others?.map((item: any, index: number) => (
                <div
                  className="clause-div cursor-pointer"
                  onClick={() => props.onClickHighlight(index, item, "others")}
                  key={index}
                >
                  <p className="clause-data">
                    <span>{index + 1}.&nbsp;</span>
                    {item.para
                      ? item.para
                      : getPara(
                          props?.sentenceData,
                          item.para_id,
                          item.table && item.table[0]?.row,
                          item.table && item.table[0]?.column
                        )}
                  </p>
                  <img
                    className="cursor-pointer"
                    style={{ zIndex: 2 }}
                    src="/static_images/delete-insight-icn.svg"
                    alt="delete-insight"
                    data-toggle="modal"
                    data-target="#deleteInsightModal"
                    onClick={() => deleteClauseModes(item, "Others")}
                  />
                </div>
              ))}
            </div>
            {/* Others block end */}
            {/* Panel block Start */}
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Panel</p>
              {!showAddPanel ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Dispute Resolution Panel",
                      panel,
                      setShowAddPanel
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Dispute Resolution Panel"
                  savedInsight={!isNullOrUndefined(panel) ? panel : []}
                  savedParentClauseDataPoint={panel}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddPanel(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="dispute_resolution"
                />
              )}
            </div>
            <div className="mt-2">
              {panel?.map((item: any, index: number) => (
                <div
                  className="clause-div cursor-pointer"
                  onClick={() => props.onClickHighlight(index, item, "panel")}
                  key={index}
                >
                  <p className="clause-data">
                    <span>{index + 1}.&nbsp;</span>
                    {item.capture}
                  </p>
                  <img
                    className="cursor-pointer"
                    style={{ zIndex: 2 }}
                    src="/static_images/delete-insight-icn.svg"
                    alt="delete-insight"
                    data-toggle="modal"
                    data-target="#deleteInsightModal"
                    onClick={() => deletePanel(item)}
                  />
                </div>
              ))}
            </div>
            {/* Panel block end */}

            {/* Venue block Start */}
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Venue</p>
              {!showAddVenue ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Dispute Resolution Venue",
                      venue,
                      setShowAddVenue
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Dispute Resolution Venue"
                  savedInsight={!isNullOrUndefined(venue) ? venue : []}
                  savedParentClauseDataPoint={venue}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddVenue(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="dispute_resolution"
                />
              )}
            </div>
            <div className="mt-2">
              {venue?.map((item: any, index: number) => (
                <div
                  className="clause-div cursor-pointer"
                  onClick={() => props.onClickHighlight(index, item, "venue")}
                  key={index}
                >
                  <p className="clause-data">
                    <span>{index + 1}.&nbsp;</span>
                    {item.capture}
                  </p>
                  <img
                    className="cursor-pointer"
                    style={{ zIndex: 2 }}
                    src="/static_images/delete-insight-icn.svg"
                    alt="delete-insight"
                    data-toggle="modal"
                    data-target="#deleteInsightModal"
                    onClick={() => deleteVenue(item)}
                  />
                </div>
              ))}
            </div>
            {/* Venue block end */}

            {/* Legal Entity block Start */}
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Act/Statute</p>
              {!showAddAct ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Dispute Resolution Act/Statute",
                      legalEntities,
                      setShowAddAct
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Dispute Resolution Act/Statute"
                  savedInsight={
                    !isNullOrUndefined(legalEntities) ? legalEntities : []
                  }
                  savedParentClauseDataPoint={legalEntities}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddAct(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="dispute_resolution"
                />
              )}
            </div>
            <div className="mt-2">
              {legalEntities?.map((item: any, index: number) => (
                <div
                  className="clause-div cursor-pointer"
                  onClick={() =>
                    props.onClickHighlight(index, item, "legalEntities")
                  }
                  key={index}
                >
                  <p className="clause-data">
                    <span>{index + 1}.&nbsp;</span>
                    {item.capture}
                  </p>
                  <img
                    className="cursor-pointer"
                    style={{ zIndex: 2 }}
                    src="/static_images/delete-insight-icn.svg"
                    alt="delete-insight"
                    data-toggle="modal"
                    data-target="#deleteInsightModal"
                    onClick={() => deleteLegalEntity(item)}
                  />
                </div>
              ))}
            </div>
            {/* Legal Entity block end */}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
