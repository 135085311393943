import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@material-ui/core";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { CLAUSE_DATA, getPara } from "./utils/ClauseTypeUtils";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";

export default function TermClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [counter, setCounter] = useState(1);
  const [isExpand, setExpand] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddStartDates, setShowAddStartDates] = useState(false);
  const [showAddEndDates, setShowAddEndDates] = useState(false);
  const [showAddDuration, setShowAddDuration] = useState(false);

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "term");
  }, [fileId, hasData]);

  const data = React.useMemo(() => {
    if (clauseData?.term) {
      let term = clauseData.term;
      if (!clauseData.term?.raw_content.term) {
        term = {
          ...term,
          raw_content: {
            ...term.raw_content,
            term: [],
          },
        };
      }
      if (!clauseData.term?.raw_content.end) {
        term = {
          ...term,
          raw_content: {
            ...term.raw_content,
            end: [],
          },
        };
      }
      if (!clauseData.term.raw_content.start) {
        term = {
          ...term,
          raw_content: {
            ...term.raw_content,
            start: [],
          },
        };
      }
      if (!clauseData.term.raw_content.duration) {
        term = {
          ...term,
          raw_content: {
            ...term.raw_content,
            duration: [],
          },
        };
      }
      return term;
    }
  }, [clauseData?.term]);

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(term, "Term Clause");
    setCounter(0);
  };

  const updates = useMemo(
    () => updatedClauseData?.term || {},
    [updatedClauseData]
  );

  const term = useMemo(() => {
    if (updates?.term) {
      let sortData = updates.term.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const duration = useMemo(() => {
    if (updates?.duration) {
      let sortData = updates.duration.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const start_date = useMemo(() => {
    if (updates?.start) {
      let sortData = updates.start.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const end_date = useMemo(() => {
    if (updates?.end) {
      let sortData = updates.end.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const effective_date = useMemo(() => {
    if (updates?.effective_date) {
      let sortData = updates.effective_date.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = useCallback(
    (item: any) => {
      let term_parent = term?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let term_duration = duration?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let term_start_date = start_date?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.sentence_id
      );
      let term_end_date = end_date?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let updatedData = {
        ...updates,
        term: term_parent,
        duration: term_duration,
        start: term_start_date,
        end: term_end_date,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "term", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, term, data, fileId]
  );

  const deleteStartDate = useCallback(
    (item: any) => {
      let term_start_date = start_date?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        start: term_start_date,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "term", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, start_date, data, fileId]
  );

  const deleteEndDate = useCallback(
    (item: any) => {
      let term_end_date = end_date?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        end: term_end_date,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "term", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, end_date, data, fileId]
  );

  const deleteDuration = useCallback(
    (item: any) => {
      let term_duration = duration?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        duration: term_duration,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "term", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, duration, data, fileId]
  );

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">{CLAUSE_DATA.term_clause["heading"]}</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", term);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${term ? term.length : 0}`
                  : term
                  ? term.length
                  : 0}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (term.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", term);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            <div className="d-flex flex-column w-100">
              {!showAdd ? (
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {term.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleAddEdit(props, "Term clause", term, setShowAdd);
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="term"
                  savedInsight={!isNullOrUndefined(term) ? term : []}
                  savedParentClauseDataPoint={term}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  durationList={props.durationList}
                  currencyList={props.currencyList}
                  contractData={props.contractData}
                  onClose={() => {
                    setShowAdd(false);
                  }}
                  clauseDataByType={data}
                  // onSubmit={(payload: any) => onUpdate(payload)}
                  updatedClauseDataByType={updates}
                />
              )}
              <div>
                <Scrollable maxHeight={200}>
                  {term?.map((item: any, index: number) => (
                    <div
                      className="clause-div cursor-pointer"
                      onClick={() =>
                        props.onClickHighlight(index, item, "Term clause")
                      }
                      key={index}
                    >
                      <p className="clause-data">
                        <span>{index + 1}. </span>
                        {getPara(
                          props?.sentenceData,
                          item.para_id,
                          item.table && item.table[0]?.row,
                          item.table && item.table[0]?.column
                        )}
                      </p>
                      <img
                        className="cursor-pointer"
                        style={{ zIndex: 2 }}
                        src="/static_images/delete-insight-icn.svg"
                        alt="delete-insight"
                        data-toggle="modal"
                        data-target="#deleteInsightModal"
                        onClick={() => deleteClause(item)}
                      />
                    </div>
                  ))}
                </Scrollable>
              </div>
            </div>
            <div className="d-flex flex-column mt-2 w-100">
              {!showAddStartDates ? (
                <div className="clause-sub-heading">
                  <p className="m-0">{CLAUSE_DATA.term_clause["start_date"]}</p>
                  {start_date?.length
                    ? start_date[0]["date"]
                      ? start_date[0]["date"]
                      : "-"
                    : "-"}
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      handleAddEdit(
                        props,
                        "Term Start Dates",
                        start_date,
                        setShowAddStartDates
                      );
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                </div>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Term Start Dates"
                  savedInsight={
                    !isNullOrUndefined(start_date) ? start_date : []
                  }
                  savedParentClauseDataPoint={start_date}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  durationList={props.durationList}
                  currencyList={props.currencyList}
                  contractData={props.contractData}
                  onClose={() => {
                    setShowAddStartDates(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="term"
                />
              )}
              <div className="mt-2">
                {start_date?.map((item: any, index: number) => (
                  <div
                    className="clause-div"
                    onClick={() =>
                      props.onClickHighlight(
                        index,
                        item,
                        CLAUSE_DATA.term_clause["start_date"]
                      )
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}. </span>
                      {item?.para ||
                        getPara(
                          props?.sentenceData,
                          item.para_id,
                          item.table && item.table[0]?.row,
                          item.table && item.table[0]?.column
                        )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteStartDate(item)}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="d-flex flex-column mt-2 w-100">
              {!showAddEndDates ? (
                <div className="clause-sub-heading">
                  <p className="m-0">{CLAUSE_DATA.term_clause["end_date"]}</p>
                  {end_date?.length
                    ? end_date[0]["date"]
                      ? end_date[0]["date"]
                      : "-"
                    : "-"}
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      handleAddEdit(
                        props,
                        "Term End Dates",
                        end_date,
                        setShowAddEndDates
                      );
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                </div>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Term End Dates"
                  savedInsight={!isNullOrUndefined(end_date) ? end_date : []}
                  savedParentClauseDataPoint={end_date}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  durationList={props.durationList}
                  currencyList={props.currencyList}
                  contractData={props.contractData}
                  onClose={() => {
                    setShowAddEndDates(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="term"
                />
              )}
              <div className="mt-2">
                {end_date?.map((item: any, index: number) => (
                  <div
                    className="clause-div"
                    onClick={() =>
                      props.onClickHighlight(
                        index,
                        item,
                        CLAUSE_DATA.term_clause["end_date"]
                      )
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}. </span>
                      {item?.para ||
                        getPara(
                          props?.sentenceData,
                          item.para_id,
                          item.table && item.table[0]?.row,
                          item.table && item.table[0]?.column
                        )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteEndDate(item)}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="d-flex flex-column mt-2 w-100">
              {!showAddDuration ? (
                <div className="clause-sub-heading">
                  <p className="m-0">{CLAUSE_DATA.term_clause["duration"]}</p>
                  {duration?.length
                    ? duration[0]["date"]
                      ? duration[0]["date"]
                      : "-"
                    : "-"}
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      handleAddEdit(
                        props,
                        "Term Duration",
                        duration,
                        setShowAddDuration
                      );
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                </div>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Term Duration"
                  savedInsight={!isNullOrUndefined(duration) ? duration : []}
                  savedParentClauseDataPoint={duration}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  durationList={props.durationList}
                  currencyList={props.currencyList}
                  contractData={props.contractData}
                  onClose={() => {
                    setShowAddDuration(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="term"
                />
              )}
              <div className="mt-2">
                {duration?.map((item: any, index: number) => (
                  <div
                    className="clause-div"
                    onClick={() =>
                      props.onClickHighlight(
                        index,
                        item,
                        CLAUSE_DATA.term_clause["duration"]
                      )
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}. </span>
                      {getPara(
                        props?.sentenceData,
                        item.para_id,
                        item.table && item.table[0]?.row,
                        item.table && item.table[0]?.column
                      )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteDuration(item)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
