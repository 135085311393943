import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@material-ui/core";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useEffect, useMemo, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";

export default function TerminationClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [counter, setCounter] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const [showAddConvenience, setShowAddConvenience] = useState(false);
  const [showAddLockInPeriod, setShowAddLockInPeriod] = useState(false);
  const [showAddEvent, setShowAddEvent] = useState(false);

  const data = useMemo(
    () => clauseData?.termination && clauseData.termination,
    [clauseData?.termination]
  );

  const terminationAtConvenienceData = useMemo(
    () =>
      clauseData?.terminationAtConvenience &&
      clauseData.terminationAtConvenience,
    [clauseData?.terminationAtConvenience]
  );

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "termination");
  }, [fileId, hasData]);

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "termination_at_convenience");
  }, [fileId, hasData]);

  const updates = useMemo(
    () => updatedClauseData?.termination || {},
    [updatedClauseData]
  );

  const updatesTerminationAtConvenience = useMemo(
    () => updatedClauseData?.terminationAtConvenience || {},
    [updatedClauseData]
  );

  const termination_clause = useMemo(() => {
    if (updates?.termination) {
      let sortData = updates.termination.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const terminationConvenience = useMemo(() => {
    if (updatesTerminationAtConvenience?.termination_at_convenience) {
      let sortData =
        updatesTerminationAtConvenience.termination_at_convenience.sort(
          function (a: any, b: any) {
            return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
          }
        );
      return sortData;
    }
    return [];
  }, [updatesTerminationAtConvenience]);

  const terminationLockin = useMemo(() => {
    if (updates?.lockin) {
      let sortData = updates.lockin.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const terminationEvent = useMemo(() => {
    if (updates?.event) {
      let sortData = updates.event.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let termination = termination_clause?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let savedPresent = terminationConvenience?.present;
      let termination_at_convenience = terminationConvenience?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      if (savedPresent) {
        termination_at_convenience.present = savedPresent;
      }
      if (termination_at_convenience?.length === 0) {
        termination_at_convenience.present = "No";
      }

      let lockin = terminationLockin?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let event = terminationEvent?.filter(
        (data: any) =>
          data.eventName !== item.eventName || data.phrase !== item.phrase
      );

      let updatedData = {
        ...updates,
        termination,
        lockin,
        event,
      };
      let diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "termination", diff, updatedData);
      }

      let updatedTerminationAtConvenienceData = {
        ...updatesTerminationAtConvenience,
        termination_at_convenience,
      };

      diff = changesets.diff(
        terminationAtConvenienceData?.raw_content,
        updatedTerminationAtConvenienceData,
        {
          children: "$index",
        }
      );

      if (diff.length > 0) {
        postClauseDataByType?.(
          fileId,
          "termination_at_convenience",
          diff,
          updatedTerminationAtConvenienceData
        );
      }

      if (diff.length === 0) {
        postClauseDataByType?.(
          fileId,
          "termination_at_convenience",
          diff,
          terminationAtConvenienceData?.raw_content
        );
      }
    },
    [updates, postClauseDataByType, termination_clause, data, fileId]
  );

  const deleteConvenience = React.useCallback(
    (item: any) => {
      let savedPresent = terminationConvenience?.present;
      let termination_at_convenience = terminationConvenience?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      if (savedPresent) {
        termination_at_convenience.present = savedPresent;
      }
      if (termination_at_convenience?.length === 0) {
        termination_at_convenience.present = "No";
      }

      let updatedData = {
        ...updatesTerminationAtConvenience,
        termination_at_convenience,
      };
      const diff = changesets.diff(
        terminationAtConvenienceData?.raw_content,
        updatedData,
        {
          children: "$index",
        }
      );

      if (diff.length > 0) {
        postClauseDataByType?.(
          fileId,
          "termination_at_convenience",
          diff,
          updatedData
        );
      }

      if (diff.length === 0) {
        postClauseDataByType?.(
          fileId,
          "termination_at_convenience",
          diff,
          terminationAtConvenienceData?.raw_content
        );
      }
    },
    [
      updatesTerminationAtConvenience,
      postClauseDataByType,
      terminationConvenience,
      terminationAtConvenienceData,
      fileId,
    ]
  );

  const deleteLockinPeriod = React.useCallback(
    (item: any) => {
      let lockin = terminationLockin?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let updatedData = {
        ...updates,
        lockin,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "termination", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, terminationLockin, data, fileId]
  );

  const deleteEvent = React.useCallback(
    (item: any) => {
      let event = terminationEvent?.filter(
        (data: any) =>
          data.eventName !== item.eventName || data.phrase !== item.phrase
      );

      let updatedData = {
        ...updates,
        event,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "termination", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, terminationEvent, data, fileId]
  );

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(termination_clause, "Termination clause");
    setCounter(0);
  };

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Termination Clause</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", termination_clause);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand && termination_clause
                  ? `${counter} / ${termination_clause?.length}`
                  : termination_clause?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (termination_clause?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", termination_clause);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {termination_clause.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleAddEdit(
                          props,
                          "Termination clause",
                          termination_clause,
                          setShowAdd
                        );
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited={props.toBeEdited}
                savedInsight={
                  !isNullOrUndefined(termination_clause)
                    ? termination_clause
                    : []
                }
                savedParentClauseDataPoint={termination_clause}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                durationList={props.durationList}
                currencyList={props.currencyList}
                contractData={props.contractData}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseData={updates}
                postClauseDataByType={postClauseDataByType}
                updatedClauseDataByType={updates}
                parentClauseType={"termination"}
                // onSubmit={(payload: any) => onUpdate(payload)}
              />
            )}
            <div className="mt-2">
              {termination_clause?.map((item: any, index: number) => (
                <div
                  className="clause-div"
                  key={index}
                  onClick={() =>
                    props.onClickHighlight(index, item, "termination")
                  }
                >
                  <p className="clause-data">
                    {item.sentence
                      ? item.sentence
                      : getPara(
                          props?.sentenceData,
                          item.para_id,
                          item.sentence_id,
                          item.table && item.table[0]?.row,
                          item.table && item.table[0]?.column
                        )}
                  </p>
                  <img
                    className="cursor-pointer"
                    style={{ zIndex: 2 }}
                    src="/static_images/delete-insight-icn.svg"
                    alt="delete-insight"
                    data-toggle="modal"
                    data-target="#deleteInsightModal"
                    onClick={() => deleteClause(item)}
                  />
                </div>
              ))}
            </div>
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Termination at Convenience</p>
              <p className="m-0">
                {terminationConvenience.length ? "Yes" : "No"}
              </p>
              {!showAddConvenience ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Termination at Convenience",
                      terminationConvenience,
                      setShowAddConvenience
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited={props.toBeEdited}
                  savedInsight={
                    !isNullOrUndefined(terminationConvenience)
                      ? terminationConvenience
                      : []
                  }
                  savedParentClauseDataPoint={terminationConvenience}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  durationList={props.durationList}
                  currencyList={props.currencyList}
                  contractData={props.contractData}
                  onClose={() => {
                    setShowAddConvenience(false);
                  }}
                  clauseDataByType={terminationAtConvenienceData}
                  updatedClauseData={updatesTerminationAtConvenience}
                  postClauseDataByType={postClauseDataByType}
                  updatedClauseDataByType={updatesTerminationAtConvenience}
                  parentClauseType={"termination_at_convenience"}
                  parentClauseData={data}
                  updatedParentClauseDataByType={updates}
                  // onSubmit={(payload: any) => onUpdate(payload)}
                />
              )}
            </div>
            <div className="mt-2">
              {terminationConvenience?.map((item: any, index: number) => (
                <div className="clause-div" key={index}>
                  <p className="clause-data">
                    {item.sentence
                      ? item.sentence
                      : getPara(
                          props?.sentenceData,
                          item.para_id,
                          item.sentence_id,
                          item.table && item.table[0]?.row,
                          item.table && item.table[0]?.column
                        )}
                  </p>
                  <img
                    className="cursor-pointer"
                    style={{ zIndex: 2 }}
                    src="/static_images/delete-insight-icn.svg"
                    alt="delete-insight"
                    data-toggle="modal"
                    data-target="#deleteInsightModal"
                    onClick={() => deleteConvenience(item)}
                  />
                </div>
              ))}
            </div>
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Lock-in Period</p>
              <p className="m-0">
                {terminationLockin?.length > 0
                  ? terminationLockin[0]?.phrase
                  : "None"}
              </p>
              {!showAddLockInPeriod ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Lock-in Period",
                      terminationLockin,
                      setShowAddLockInPeriod
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited={props.toBeEdited}
                  savedInsight={
                    !isNullOrUndefined(terminationLockin)
                      ? terminationLockin
                      : []
                  }
                  savedParentClauseDataPoint={terminationLockin}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  durationList={props.durationList}
                  currencyList={props.currencyList}
                  contractData={props.contractData}
                  onClose={() => {
                    setShowAddLockInPeriod(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseData={updates}
                  postClauseDataByType={postClauseDataByType}
                  updatedClauseDataByType={updates}
                  parentClauseType={"termination"}
                  // onSubmit={(payload: any) => onUpdate(payload)}
                />
              )}
            </div>
            <div className="mt-2">
              {terminationLockin?.map((item: any, index: number) => (
                <div className="clause-div" key={index}>
                  <p className="clause-data">
                    {item.sentence
                      ? item.sentence
                      : getPara(
                          props?.sentenceData,
                          item.para_id,
                          item.sentence_id,
                          item.table && item.table[0]?.row,
                          item.table && item.table[0]?.column
                        )}
                  </p>
                  <img
                    className="cursor-pointer"
                    style={{ zIndex: 2 }}
                    src="/static_images/delete-insight-icn.svg"
                    alt="delete-insight"
                    data-toggle="modal"
                    data-target="#deleteInsightModal"
                    onClick={() => deleteLockinPeriod(item)}
                  />
                </div>
              ))}
            </div>
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Event</p>
              <div className="col-md-1">
                {terminationEvent ? terminationEvent.length : 0}
              </div>
              {!showAddEvent ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Termination Event",
                      terminationEvent,
                      setShowAddEvent
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited={props.toBeEdited}
                  savedInsight={
                    !isNullOrUndefined(terminationEvent) ? terminationEvent : []
                  }
                  savedParentClauseDataPoint={terminationEvent}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  durationList={props.durationList}
                  currencyList={props.currencyList}
                  contractData={props.contractData}
                  onClose={() => {
                    setShowAddEvent(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseData={updates}
                  postClauseDataByType={postClauseDataByType}
                  updatedClauseDataByType={updates}
                  parentClauseType={"termination"}
                />
              )}
            </div>
            <div className="mt-2">
              {terminationEvent?.map((item: any, index: number) => (
                <div className="clause-div" key={index}>
                  <p className="clause-data">{item.phrase}</p>
                  <img
                    className="cursor-pointer"
                    style={{ zIndex: 2 }}
                    src="/static_images/delete-insight-icn.svg"
                    alt="delete-insight"
                    data-toggle="modal"
                    data-target="#deleteInsightModal"
                    onClick={() => deleteEvent(item)}
                  />
                </div>
              ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
