import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@material-ui/core";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useEffect, useMemo, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { LinkPhraseRequest } from "../../State/documentState";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";

export default function ConsentClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;
  const data = React.useMemo(
    () => clauseData?.consent && clauseData.consent,
    [clauseData?.consent]
  );

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "consent");
  }, [fileId, hasData]);

  const [counter, setCounter] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const [showAddRegulatory, setShowAddRegulatory] = useState(false);
  const [showAddAuthority, setShowAddAuthority] = useState<boolean>(false);
  const [authorityId, setAuthorityId] = useState<number>(-1);
  const [regulatoryId, setRegulatoryId] = useState<number>(-1);

  const updates = useMemo(
    () => updatedClauseData?.consent,
    [updatedClauseData]
  );

  const consent_clause = useMemo(() => {
    if (updates?.consent) {
      let sortData = updates.consent.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const consent_regulatory_bi = useMemo(() => {
    if (updates?.consent_regulatory_bi) {
      let sortData = updates.consent_regulatory_bi.sort(function (
        a: any,
        b: any
      ) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let consent = consent_clause?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let updatedData = {
        ...updates,
        consent,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "consent", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, consent_clause, data, fileId]
  );

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(updates?.consent, "Consent Clause");
    setCounter(0);
  };

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Consent Clause</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();

                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", updates?.consent);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${updates?.consent?.length}`
                  : updates?.consent?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();

                    if (updates?.consent?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", updates?.consent);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {consent_clause.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        props.onEdit(
                          "Consent Clause",
                          0,
                          false,
                          [],
                          -1,
                          "",
                          consent_clause
                        );
                        setShowAdd(true);
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited="Consent Clause"
                savedInsight={
                  !isNullOrUndefined(consent_clause) ? consent_clause : []
                }
                savedParentClauseDataPoint={consent_clause}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
                parentClauseType="consent"
              />
            )}
            <div className="d-flex flex-column mt-2">
              <div className="mt-2">
                <Scrollable maxHeight={200}>
                  {consent_clause?.map((item: any, index: number) => (
                    <React.Fragment key={index}>
                      <>
                        <div
                          className="clause-div cursor-pointer"
                          onClick={() =>
                            props.onClickHighlight(index, item, "consent")
                          }
                          key={index}
                        >
                          <p className="clause-data">
                            <span>{index + 1}.&nbsp;</span>
                            {item.sentence}
                          </p>
                          <img
                            className="cursor-pointer"
                            style={{ zIndex: 2 }}
                            src="/static_images/delete-insight-icn.svg"
                            alt="delete-insight"
                            data-toggle="modal"
                            data-target="#deleteInsightModal"
                            onClick={() => deleteClause(item)}
                          />
                        </div>
                        <div className="clause-sub-heading">
                          <p className="m-0">Regulatory</p>
                          {item.type === "Regulatory" ? "Yes" : "No"}
                          {!showAddRegulatory ? (
                            <DarkTooltip
                              title="Add"
                              placement="right-end"
                              onClick={() => {
                                handleAddEdit(
                                  props,
                                  "Consent Regulatory",
                                  consent_clause,
                                  setShowAddRegulatory
                                );
                                setRegulatoryId(index);
                                setShowAddAuthority(false);
                              }}
                            >
                              <img src="/static_images/add-icn.svg" alt="add" />
                            </DarkTooltip>
                          ) : regulatoryId === index ? (
                            <p
                              className="col-md-10 my-2 ml-0"
                              style={{ display: "inline-block", float: "left" }}
                            >
                              <EditFeature
                                fileId={props.fileId}
                                toBeEdited={props.toBeEdited}
                                savedInsight={
                                  !isNullOrUndefined(consent_clause)
                                    ? consent_clause
                                    : []
                                }
                                savedParentClauseDataPoint={consent_clause}
                                editOptionSelected={(selected: boolean) => {
                                  props.editOptionSelected(selected);
                                }}
                                childInEditId={props.childInEditId}
                                durationList={props.durationList}
                                currencyList={props.currencyList}
                                contractData={props.contractData}
                                onClose={() => {
                                  setShowAddRegulatory(false);
                                }}
                                clauseDataByType={data}
                                updatedClauseData={updates}
                                postClauseDataByType={postClauseDataByType}
                                updatedClauseDataByType={updates}
                                parentClauseType={"consent"}
                                clauseItem={item}
                              />
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                      <div className="clause-sub-heading">
                        <p className="m-0" style={{ width: "90px" }}>
                          Authority
                        </p>

                        {!showAddAuthority ? (
                          <DarkTooltip
                            title={"Add"}
                            placement="right-end"
                            onClick={() => {
                              handleAddEdit(
                                props,
                                "Consent Authority",
                                consent_regulatory_bi,
                                setShowAddAuthority
                              );
                              setAuthorityId(index);
                              setShowAddRegulatory(false);
                            }}
                          >
                            <img src="/static_images/add-icn.svg" alt="add" />
                          </DarkTooltip>
                        ) : (
                          authorityId === index && (
                            <div style={{ width: "100%" }}>
                              <EditFeature
                                fileId={props.fileId}
                                toBeEdited={props.toBeEdited}
                                savedInsight={
                                  !isNullOrUndefined(consent_regulatory_bi)
                                    ? consent_regulatory_bi
                                    : []
                                }
                                savedParentClauseDataPoint={
                                  consent_regulatory_bi
                                }
                                editOptionSelected={(selected: boolean) => {
                                  props.editOptionSelected(selected);
                                }}
                                childInEditId={props.childInEditId}
                                durationList={props.durationList}
                                currencyList={props.currencyList}
                                contractData={props.contractData}
                                onClose={() => {
                                  setShowAddAuthority(false);
                                }}
                                clauseDataByType={data}
                                updatedClauseData={updates}
                                postClauseDataByType={postClauseDataByType}
                                updatedClauseDataByType={updates}
                                parentClauseType={"consent"}
                                clauseItem={item}
                              />
                            </div>
                          )
                        )}
                      </div>
                      {consent_regulatory_bi?.map(
                        (authorityItem: any, index: number) => (
                          <React.Fragment key={index}>
                            {item.para_id === authorityItem.para_id &&
                            item.sentence_id === authorityItem.sentence_id ? (
                              <>
                                <>
                                  {Object.keys(authorityItem?.extraction).map(
                                    (keyName, index) => (
                                      <React.Fragment key={index}>
                                        <p className="clause-data">
                                          <span
                                            style={{
                                              whiteSpace: "pre",
                                              marginLeft: "20px",
                                              marginTop: "10px",
                                              display: "inline-block",
                                            }}
                                          >
                                            {index + 1}.{" "}
                                            {
                                              authorityItem.extraction[keyName]
                                                .entity
                                            }
                                          </span>
                                        </p>
                                        <br />
                                      </React.Fragment>
                                    )
                                  )}
                                </>
                              </>
                            ) : (
                              <></>
                            )}
                          </React.Fragment>
                        )
                      )}
                    </React.Fragment>
                  ))}
                </Scrollable>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
