import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@material-ui/core";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useEffect, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";

export default function InsuranceClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;
  const [counter, setCounter] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [isExpand, setExpand] = useState(false);

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "insurance");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.insurance && clauseData.insurance,
    [clauseData?.insurance]
  );

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(data?.raw_content?.insurance, "Insurance Clause");
    setCounter(0);
  };

  const updates = React.useMemo(
    () => updatedClauseData?.insurance || {},
    [updatedClauseData]
  );

  const insurance = React.useMemo(() => {
    if (updates?.insurance) {
      let sortData = updates.insurance.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let value = insurance?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        insurance: value,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "insurance", diff, updatedData);
      }
    },
    [updates, data, fileId, insurance, postClauseDataByType]
  );

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Insurance Clause</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", insurance);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${insurance?.length}`
                  : insurance?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    props.onScroll("down", insurance);
                    e.stopPropagation();
                    if (insurance?.length > counter) {
                      setCounter((prev) => prev + 1);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {insurance.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleAddEdit(
                          props,
                          "insurance",
                          insurance,
                          setShowAdd
                        );
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited={props.toBeEdited}
                savedInsight={!isNullOrUndefined(insurance) ? insurance : []}
                savedParentClauseDataPoint={insurance}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
              />
            )}
            <div className="mt-2">
              {insurance?.map((item: any, index: number) => (
                <div
                  className="clause-div cursor-pointer"
                  onClick={() =>
                    props.onClickHighlight(index, item, "insurance")
                  }
                  key={index}
                >
                  <p className="clause-data">
                    <span>{index + 1}.&nbsp;</span>
                    {item.para
                      ? item.para
                      : getPara(
                          props?.sentenceData,
                          item.para_id,
                          item.sentence_id,
                          item.table && item.table[0]?.row,
                          item.table && item.table[0]?.column
                        )}
                  </p>
                  <img
                    className="cursor-pointer"
                    style={{ zIndex: 2 }}
                    src="/static_images/delete-insight-icn.svg"
                    alt="delete-insight"
                    data-toggle="modal"
                    data-target="#deleteInsightModal"
                    onClick={() => deleteClause(item)}
                  />
                </div>
              ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
