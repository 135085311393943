import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import { SITE_API_BY_REALM_NAME } from "../../../Configuration/global";
import {
  DELETENOTIFICATION,
  DeleteNotification,
  GETNOTIFICATIONSDATA,
  GetNotificationsData,
  GetUserProfileID,
  GETUSERPROFILEID,
  MARKNOTIFICATION,
  MarkNotification,
  NOTIFICATIONALERT,
  NotificationAlert,
} from "../Actions/def";
import { NotificationData } from "../State/notificationState";
import NotificationGenerator from "../Actions/gen";
import NotificationAPI from "../Actions/API";
import {
  getKeyCloakRealmFromLS,
  setLocalStorage,
  setUserId,
} from "../../../Authentication/Actions/authentication";
import { ResponseGenerator } from "../../../Types";
const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());

function* getUserProfileID(action: GetUserProfileID) {
  // const url = SITEAPI + "user/profile";
  const url = process.env.REACT_APP_RIVERUS_CLM_API + "users/profile/";
  try {
    const response:ResponseGenerator = yield call(NotificationAPI.getUserProfileID, url);
    switch (response.status) {
      case 200: {
        const parsed = response.data.id;
        setUserId("user_id", response.data.id);
        setLocalStorage("user_profile", response.data);
        yield put(NotificationGenerator.getUserProfileIDSuccess(parsed));
        break;
      }
      default: {
        yield put(NotificationGenerator.getUserProfileIDFailure());
      }
    }
  } catch (error) {
    yield put(NotificationGenerator.getUserProfileIDFailure());
  }
  // -----------------------------
}

function* getNotification(action: NotificationAlert) {
  /*const notificationSocketClient = socketIOClient(
   HOST + "/notification-alert-namespace"
   );
   notificationSocketClient.on(
   "notification-alert",
   (notificationInterface: any) => {
   let notificationData: NotificationData = {
   notificationID: notificationInterface.notificationID,
   requestID: notificationInterface.requestID,
   method: notificationInterface.method,
   type: notificationInterface.type,
   profileID: notificationInterface.profileID,
   taskTitle: notificationInterface.taskTitle,
   fileID: notificationInterface.fileID,
   fileName: notificationInterface.fileName,
   clauseType: notificationInterface.clauseType,
   biType: notificationInterface.biType,
   progress: notificationInterface.progress,
   state: notificationInterface.state,
   alertDate: notificationInterface.alertDate,
   isRead: notificationInterface.isRead,
   task: {
   id: "",
   contract_id: "",
   contract_file_name: "",
   contract_clause_type: "",
   contract_business_intelligence_type: "",
   title: "",
   progress: "",
   status: "",
   reminder_until: "",
   created_by: "",
   },
   id: "",
   created_at: "",
   details: {
   id: "",
   contract_id: "",
   contract_file_name: "",
   contract_clause_type: "",
   contract_business_intelligence_type: "",
   title: "",
   progress: "",
   status: "",
   reminder_until: "",
   created_by: "",
   task: {
   id: "",
   contract_id: "",
   contract_file_name: "",
   contract_clause_type: "",
   contract_business_intelligence_type: "",
   title: "",
   progress: "",
   status: "",
   reminder_until: "",
   created_by: "",
   },
   message: "",
   },
   status: "",
   };

   // console.log(notificationData.taskTitle);

   store.dispatch(
   NotificationGenerator.notificationAlertSuccess(notificationData)
   );
   }
   );*/
}

function* getNotificationsData(action: GetNotificationsData) {
  // let url = SITEAPI + "notification/data";
  // let url = "http://localhost:6064/contracts/notification/data";
  let url = process.env.REACT_APP_RIVERUS_CLM_API + "notifications/";

  try {
    let response:ResponseGenerator = yield call(NotificationAPI.getNotificationsData, url);

    switch (response.status) {
      case 200: {
        // let notificationsDataArray = response.data
        //   .queryResult as NotificationData[];
        let notificationsDataArray = response.data
            .results as NotificationData[];

        yield put(
            NotificationGenerator.getNotificationsDataSuccess(
                notificationsDataArray
            )
        );
        break;
      }
      default: {
        yield put(NotificationGenerator.getNotificationsDataFailure());
      }
    }
  } catch (error) {
    yield put(NotificationGenerator.getNotificationsDataFailure());
  }
}

function* markNotification(action: MarkNotification) {
  let url = SITEAPI + "notification/mark";
  let notificationID = action.payload.id;

  try {
    let response:ResponseGenerator = yield call(
        NotificationAPI.markNotification,
        url,
        notificationID
    );

    switch (response.status) {
      case 200: {
        yield put(NotificationGenerator.markNotificationSuccess());
        yield put(NotificationGenerator.getNotificationsData());
        break;
      }
      default: {
        yield put(NotificationGenerator.markNotificationFailure());
      }
    }
  } catch (error) {
    yield put(NotificationGenerator.markNotificationFailure());
  }
}

function* deleteNotification(action: DeleteNotification) {
  // let url = SITEAPI + "notification/delete";
  let notificationID = action.payload.id;
  let url =
      process.env.REACT_APP_RIVERUS_CLM_API +
      "notifications/" +
      notificationID +
      "/";

  try {
    let response:ResponseGenerator = yield call(
        NotificationAPI.deleteNotification,
        url,
        notificationID
    );

    switch (response.status) {
      case 204: {
        yield put(NotificationGenerator.deleteNotificationSuccess());
        yield put(NotificationGenerator.getNotificationsData());
        break;
      }
      default: {
        yield put(NotificationGenerator.deleteNotificationFailure());
      }
    }
  } catch (error) {
    yield put(NotificationGenerator.deleteNotificationFailure());
  }
}
export default function* notificationWatcher() {
  yield all([
    takeLatest(GETUSERPROFILEID, getUserProfileID),
    takeEvery(NOTIFICATIONALERT, getNotification),
    takeLatest(GETNOTIFICATIONSDATA, getNotificationsData),
    takeLatest(MARKNOTIFICATION, markNotification),
    takeLatest(DELETENOTIFICATION, deleteNotification),
  ]);
}
