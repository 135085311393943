import * as changesets from "json-diff-ts";

import { ClauseType, ClauseTypeMap } from "../../../../Constants/ClauseType";
import {
  LinkSentenceRequest,
  SentencesData,
  editedSentences,
  sentenceInfo,
  tableInfo,
} from "../../../../State/documentState";
import React, { Component } from "react";
import {
  dataForMap,
  getSentencesFromChild,
  getTableCellsFromChild,
} from "../../../Utils/docUtils";
import {
  deleteDataFromObligation,
  getClauseDataFormat,
  getClauseObjects,
  getEditedPayload,
} from "../../../ClauseComponent/utils/ClauseTypeUtils";

import SaveOrCancelSentence from "../saveOrCancelSentence";
import { duration } from "@material-ui/core";
import { isNullOrUndefined } from "is-what";
import { table } from "console";

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editPresentSentences: (presentValue: LinkSentenceRequest) => void;
  dataPointName: string;
  savedHighlightedSentences: sentenceInfo[] | null;
  saveHighlightedSentences: (
    savedHighlightedSentences: sentenceInfo[] | null
  ) => void;
  savedPresentData: any;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  fileId: string;
  clauseType: string;
  wholeData: any;
  postClauseDataByType: (
    fileId: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  onClose: any;
  clauseData: any;
}

interface State {
  highlight: boolean;
  present: string;
  origData: any;
  newAdded: boolean;
}

export const regulatoryMap: any = {
  Yes: "Non-Regulatory",
  No: "Regulatory",
};

export default class ConsentAddEdit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      highlight: false,
      present: "Yes",
      origData: props.clauseDataByType,
      newAdded: false,
    };
  }

  render() {
    let {
      dataPointName,
      editOptionSelected,
      saveHighlightedTableCells,
      saveHighlightedSentences,
      savedHighlightedSentences,
      savedHighlightedTableCells,
      savedPresentData,
      onClose,
    } = this.props;
    let { present } = this.state;
    return (
      <div className="row toc-content toc-content-default">
        <div className="col-md-12">
          <div className="row mt-1">
            <div
              className="col-md-12 m-0 bi-label-clickable"
              style={{ fontWeight: 600 }}
            >
              Add {dataPointName}
            </div>
            <div className="col-md-12 my-1 edit-title-header">
              Add/Edit the {dataPointName} clause of your contract here
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-5 bi-label-clickable edit-date-title"
              style={{ fontWeight: 600 }}
            >
              Present
            </div>
            <div
              className="col-md-3"
              style={{ margin: "auto" }}
              onClick={() => this.setState({ present: "Yes" })}
            >
              <img
                className="cursor-pointer"
                src={
                  present === "Yes"
                    ? "/static_images/radio-active.svg"
                    : "/static_images/radio-inactive.svg"
                }
                alt="radio-btn"
              />
              &nbsp;&nbsp;
              <span>Yes</span>
            </div>
            <div
              className="col-md-3"
              style={{ margin: "auto" }}
              onClick={() => this.setState({ present: "No" })}
            >
              <img
                className="cursor-pointer"
                src={
                  present === "No"
                    ? "/static_images/radio-active.svg"
                    : "/static_images/radio-inactive.svg"
                }
                alt="radio-btn"
              />
              &nbsp;&nbsp;
              <span>No</span>
            </div>
          </div>
          <SaveOrCancelSentence
            enableHighlightOption={present === "Yes"}
            dataPointName={dataPointName}
            editOptionSelected={editOptionSelected}
            editDataPoint={() => {
              this.onSaveClause();
            }}
            savedHighlightedSentences={savedHighlightedSentences}
            saveHighlightedDataPoint={this.saveHighlightedDataPointCallback}
            enableSaveBtn={true}
            saveHighlightedSentences={(
              savedHighlightedSentences: sentenceInfo[] | null
            ) => saveHighlightedSentences(savedHighlightedSentences)}
            savedHighlightedTableCells={savedHighlightedTableCells}
            saveHighlightedTableCells={(
              savedHighlightedTableCells: tableInfo[] | null
            ) => saveHighlightedTableCells(savedHighlightedTableCells)}
            previouslyLinkedSentences={
              getSentencesFromChild(savedPresentData).length > 0
                ? getSentencesFromChild(savedPresentData)
                : undefined
            }
            previouslyLinkedTableCells={
              getTableCellsFromChild(savedPresentData).length > 0
                ? getTableCellsFromChild(savedPresentData)
                : undefined
            }
            onClose={onClose}
            otherClauses={(type: string) => {}}
          />
        </div>
      </div>
    );
  }

  saveHighlightedDataPointCallback = () => {
    this.props.saveHighlightedDataPoint(this.props.dataPointName);
  };

  onSave(addSentenceRequest: any) {
    let {
      clauseType,
      sentenceData,
      clauseDataByType,
      fileId,
      updatedClauseDataByType,
      postClauseDataByType,
      parentClauseType,
      updatedClauseData,
      clauseData,
    } = this.props;

    let updatedData = updatedClauseDataByType;
    let newData = updatedData;
    let obligationData = updatedClauseData.obligationStatements;

    if (this.state.present === "Yes") {
      if (addSentenceRequest?.editedSentences?.upsert) {
        let addedData = addSentenceRequest.editedSentences.upsert;
        for (let i = 0; i < addedData.length; i++) {
          newData = getClauseDataFormat(
            "add",
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData
          );
          obligationData = deleteDataFromObligation(
            "sentence",
            addedData[i],
            obligationData
          );
        }
      }
      if (addSentenceRequest?.editedSentences?.deleted) {
        let deletedData = addSentenceRequest.editedSentences.deleted;
        for (let i = 0; i < deletedData.length; i++) {
          newData = getClauseDataFormat(
            "remove",
            clauseType as ClauseType,
            deletedData[i],
            newData,
            sentenceData
          );
        }
      }
    } else {
      newData = getClauseObjects(this.props.clauseType as ClauseType);
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });

    const obligationDiff = changesets.diff(
      clauseData?.obligationStatements?.raw_content,
      obligationData,
      { children: "$index" }
    );

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType || clauseType,
        diff,
        newData
      );
    }
    if (clauseType !== "obligation_statements") {
      if (obligationDiff.length > 0) {
        postClauseDataByType(
          fileId,
          "obligation_statements",
          obligationDiff,
          obligationData
        );
      }
    }
  }

  onSaveClause() {
    let addSentenceRequest = this.addOrRemovePresent("add");
    this.props.editOptionSelected(false);
    this.props.saveHighlightedTableCells(null);
    this.props.saveHighlightedSentences(null);
    this.setState({ highlight: false });
    this.onSave(addSentenceRequest);
    this.props.onClose();
  }

  addOrRemovePresent(action: string): LinkSentenceRequest {
    let {
      savedHighlightedSentences,
      savedHighlightedTableCells,
      savedPresentData,
    } = this.props;
    let tempPresentList: LinkSentenceRequest = { data: "", mode: "" };

    //edited sentences
    let previousLinkedSentences = getSentencesFromChild(savedPresentData);
    let editedSentencesObject = this.getAddedAndDeletedSentences(
      savedHighlightedSentences,
      previousLinkedSentences
    );

    //edited table cells
    let previousLinkedTableCells = getTableCellsFromChild(savedPresentData);
    let editedTableCellsObject = this.getAddedAndDeletedTableCells(
      savedHighlightedTableCells,
      previousLinkedTableCells
    );

    //merge both
    let mergeEditedSentences = this.mergeEditedSentences(
      editedTableCellsObject,
      editedSentencesObject
    );

    if (action === "add") {
      this.setState({ newAdded: true });
      if (savedHighlightedSentences || savedHighlightedTableCells) {
        tempPresentList = {
          data: this.state.present,
          mode: "manual",
          editedSentences: mergeEditedSentences,
        };
      } else {
        tempPresentList = {
          data: this.state.present,
          mode: "retain",
        };
      }
    }
    return tempPresentList;
  }

  getAddedAndDeletedSentences(
    changedLinkedSentences: sentenceInfo[] | null,
    previousLinkedSentences: sentenceInfo[]
  ) {
    let addedSentences: sentenceInfo[] = [];
    let deletedSentences: sentenceInfo[] = [];

    if (previousLinkedSentences.length) {
      if (changedLinkedSentences) {
        if (changedLinkedSentences.length) {
          //get newly added elements
          for (let i = 0; i < changedLinkedSentences.length; i++) {
            let addedExists = false;
            for (let j = 0; j < previousLinkedSentences.length; j++) {
              if (
                changedLinkedSentences[i].paraId ===
                previousLinkedSentences[j].paraId
              ) {
                if (
                  changedLinkedSentences[i].sentenceId ===
                  previousLinkedSentences[j].sentenceId
                ) {
                  addedExists = true;
                  break;
                }
              }
            }
            if (!addedExists) {
              addedSentences.push({
                paraId: changedLinkedSentences[i].paraId,
                sentenceId: changedLinkedSentences[i].sentenceId,
                rowId: -1,
                columnId: -1,
                phrase: "",
                typestring: "",
                startWordId: -1,
                endWordId: -1,
              });
            }
          }

          //get deleted elements
          for (let i = 0; i < previousLinkedSentences.length; i++) {
            let deletedExists = false;
            for (let j = 0; j < changedLinkedSentences.length; j++) {
              if (
                previousLinkedSentences[i].paraId ===
                changedLinkedSentences[j].paraId
              ) {
                if (
                  previousLinkedSentences[i].sentenceId ===
                  changedLinkedSentences[j].sentenceId
                ) {
                  deletedExists = true;
                  break;
                }
              }
            }
            if (!deletedExists) {
              deletedSentences.push({
                paraId: previousLinkedSentences[i].paraId,
                sentenceId: previousLinkedSentences[i].sentenceId,
                rowId: previousLinkedSentences[i].rowId,
                columnId: previousLinkedSentences[i].columnId,
              });
            }
          }
        } else if (changedLinkedSentences.length === 0) {
          for (let i = 0; i < previousLinkedSentences.length; i++) {
            deletedSentences.push({
              paraId: previousLinkedSentences[i].paraId,
              sentenceId: previousLinkedSentences[i].sentenceId,
              rowId: previousLinkedSentences[i].rowId,
              columnId: previousLinkedSentences[i].columnId,
            });
          }
        }
      }
    } else {
      if (changedLinkedSentences) {
        if (changedLinkedSentences.length) {
          //adding for first time, newly added elements
          for (let i = 0; i < changedLinkedSentences.length; i++) {
            addedSentences.push({
              paraId: changedLinkedSentences[i].paraId,
              sentenceId: changedLinkedSentences[i].sentenceId,
              rowId: changedLinkedSentences[i].rowId,
              columnId: changedLinkedSentences[i].columnId,
              phrase: "",
              typestring: "",
              startWordId: -1,
              endWordId: -1,
            });
          }
        }
      }
    }

    let addedDeletedSentences: editedSentences = {
      upsert: addedSentences,
      deleted: deletedSentences,
    };

    return addedDeletedSentences;
  }

  getAddedAndDeletedTableCells(
    changedLinkedTableCells: tableInfo[] | null,
    previousLinkedTableCells: tableInfo[]
  ) {
    let addedTableCells: tableInfo[] = [];
    let deletedTableCells: tableInfo[] = [];
    if (previousLinkedTableCells.length && previousLinkedTableCells) {
      if (changedLinkedTableCells && changedLinkedTableCells.length) {
        //newly added
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          let addedCellExists = false;
          for (let j = 0; j < previousLinkedTableCells.length; j++) {
            if (
              changedLinkedTableCells[i].paraId ===
                previousLinkedTableCells[j].paraId &&
              changedLinkedTableCells[i].rowId ===
                previousLinkedTableCells[j].rowId &&
              changedLinkedTableCells[i].columnId ===
                previousLinkedTableCells[j].columnId
            ) {
              addedCellExists = true;
              break;
            }
          }
          if (addedCellExists === false) {
            addedTableCells.push({
              paraId: changedLinkedTableCells[i].paraId,
              rowId: changedLinkedTableCells[i].rowId,
              columnId: changedLinkedTableCells[i].columnId,
              phrase: "",
              typestring: "",
              startWordId: -1,
              endWordId: -1,
            });
          }
        }

        //deleted elements
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          let deletedCellExists = false;
          for (let j = 0; j < changedLinkedTableCells.length; j++) {
            if (
              previousLinkedTableCells[i].paraId ===
                changedLinkedTableCells[j].paraId &&
              previousLinkedTableCells[i].rowId ===
                changedLinkedTableCells[j].rowId &&
              previousLinkedTableCells[i].columnId ===
                changedLinkedTableCells[j].columnId
            ) {
              deletedCellExists = true;
              break;
            }
          }
          if (!deletedCellExists) {
            deletedTableCells.push({
              paraId: previousLinkedTableCells[i].paraId,
              rowId: previousLinkedTableCells[i].rowId,
              columnId: previousLinkedTableCells[i].columnId,
            });
          }
        }
      } else {
        //all deleted
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          deletedTableCells.push({
            paraId: previousLinkedTableCells[i].paraId,
            rowId: previousLinkedTableCells[i].rowId,
            columnId: previousLinkedTableCells[i].columnId,
          });
        }
      }
    } else if (changedLinkedTableCells && changedLinkedTableCells.length) {
      //newly added
      for (let i = 0; i < changedLinkedTableCells.length; i++) {
        addedTableCells.push({
          paraId: changedLinkedTableCells[i].paraId,
          rowId: changedLinkedTableCells[i].rowId,
          columnId: changedLinkedTableCells[i].columnId,
          phrase: "",
          typestring: "",
          startWordId: -1,
          endWordId: -1,
        });
      }
    }

    //Convert table cells json to sentence level json
    let upsertSentences: sentenceInfo[] = [];
    let deletedSentences: sentenceInfo[] = [];

    for (let i = 0; i < addedTableCells.length; i++) {
      upsertSentences.push({
        paraId: addedTableCells[i].paraId,
        sentenceId: -1,
        rowId: addedTableCells[i].rowId,
        columnId: addedTableCells[i].columnId,
        phrase: addedTableCells[i].phrase,
        typestring: addedTableCells[i].typestring,
        startWordId: -1,
        endWordId: -1,
      });
    }

    for (let i = 0; i < deletedTableCells.length; i++) {
      deletedSentences.push({
        paraId: deletedTableCells[i].paraId,
        sentenceId: -1,
        rowId: deletedTableCells[i].rowId,
        columnId: deletedTableCells[i].columnId,
      });
    }

    let editedTableCellsAsSentence: editedSentences = {
      upsert: upsertSentences,
      deleted: deletedSentences,
    };
    return editedTableCellsAsSentence;
  }

  mergeEditedSentences(
    firstEditedSentences: editedSentences,
    secondEditedSentences: editedSentences
  ) {
    let { dataPointName } = this.props;
    let mergedAddedSentences: sentenceInfo[] =
      firstEditedSentences.upsert.concat(secondEditedSentences.upsert);
    let mergedDeletedSentences: sentenceInfo[] =
      firstEditedSentences.deleted.concat(secondEditedSentences.deleted);

    let mergedEditedSentences: editedSentences = {
      upsert: mergedAddedSentences,
      deleted: mergedDeletedSentences,
      dataFor: dataForMap[dataPointName],
    };
    return mergedEditedSentences;
  }
}
