import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@material-ui/core";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { getEditedPayload, getPara } from "./utils/ClauseTypeUtils";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { handleAddEdit } from "./Components/ClauseComponent";

export default function NoticeClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [counter, setCounter] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [isExpand, setExpand] = useState(false);

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "notice");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.notice && clauseData.notice,
    [clauseData]
  );

  // const [showNoticeRenewal, setShowNoticeRenewal] = useState(false);

  const updates = React.useMemo(
    () => updatedClauseData?.notice || {},
    [updatedClauseData]
  );

  const notice = React.useMemo(() => {
    if (updates?.notice) {
      let sortData = updates.notice.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(updates?.notice, "notice");
    setCounter(0);
  };

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let value = notice?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let updatedData = {
        ...updates,
        notice: value,
      };
      const diff = changesets.diff(data?.raw_content || {}, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "notice", diff, updatedData);
      }
    },
    [updates, data, fileId, notice, postClauseDataByType]
  );

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Notice Obligations</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", notice);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand ? `${counter} / ${notice?.length}` : notice?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (notice?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", notice);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {notice.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleAddEdit(props, "notice", notice, setShowAdd);
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited={props.toBeEdited}
                savedInsight={notice ? notice : []}
                savedParentClauseDataPoint={notice}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
              />
            )}
            <div className="d-flex flex-column mt-2">
              <div className="mt-2">
                <Scrollable maxHeight={200}>
                  {notice?.map((item: any, index: number) => (
                    <div
                      className="clause-div cursor-pointer"
                      onClick={() =>
                        props.onClickHighlight(index, item, "non_compete")
                      }
                      key={index}
                    >
                      <p className="clause-data">
                        <span>{index + 1}.&nbsp;</span>
                        {item.sentence
                          ? item.sentence
                          : getPara(
                              props?.sentenceData,
                              item.para_id,
                              item.sentence_id,
                              item.table && item.table[0]?.row,
                              item.table && item.table[0]?.column
                            )}
                      </p>
                      <img
                        className="cursor-pointer"
                        style={{ zIndex: 2 }}
                        src="/static_images/delete-insight-icn.svg"
                        alt="delete-insight"
                        data-toggle="modal"
                        data-target="#deleteInsightModal"
                        onClick={() => deleteClause(item)}
                      />
                    </div>
                  ))}
                </Scrollable>
              </div>
            </div>
            {/* <div className="clause-sub-heading mt-2">
              <p className="m-0">Notice Renewal</p>
              {!showNoticeRenewal ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "notice_renewal",
                      notice_renewal,
                      setShowNoticeRenewal
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited={props.toBeEdited}
                  savedInsight={
                    data?.raw_content?.notice_renewal
                      ? data.raw_content.notice_renewal
                      : []
                  }
                  savedParentClauseDataPoint={data?.raw_content?.notice_renewal}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  
                  onClose={() => {
                    setShowNoticeRenewal(false);
                  }}
                  clauseDataByType={data}
                updatedClauseDataByType={updates}

                />
              )}
            </div>
            <div className="d-flex flex-column mt-2">
              <div className="mt-2">
                {updates?.notice_renewal?.map((item: any, key: number) => (
                  <div className="clause-div" key={key}>
                    <p className="clause-data">
                      {getPara(
                        props?.sentenceData,
                        item.para_id,
                        item.sentence_id
                      )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      // onClick={() => deleteClause(key)}
                    />
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
